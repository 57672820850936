html,
body {
    font-size: 17px;
    line-height: 1.5;
}

::-moz-selection { background: @beige; }
::selection { background: @beige; }

body {
    background-color: #fff;
    font-family: @serif;
	overflow-x: hidden;
	position: relative;
	left: 0;
	.transition(all ease-in-out .3s);

    &.push-right {
    	left: 350px;
    }
}

#content {
    background-color: #f7f4e5;
    .box-shadow(1px 1px 2px fade(#000, 10%));

    .no-background & {
        padding-top: 105px;
    }

    .content-text {
        .container-full {
            .article-text {
                #k2Container {
                    padding: 0;
                    margin: 0;
                    margin-top: 50px;

                    .itemBody {
                        z-index: 1;
                        /*padding: 80px;*/
                        padding: 3.5vw;
                        /*margin-left: 30px;*/
                        margin-left: 0px;
                        text-align: left;

                        &:after {
                            content: "";
                            position: absolute;
                            top: 0;
                            right: -80px;
                            width: 80px;
                            height: 100%;
                            background-color: @beige;
                        }

                        &.col-md-24,
                        &.col-lg-24 {
                            margin-left: 0;
                            
                            &:after {
                                content: none;
                            }
                        }

                        h2.itemTitle {
                            text-transform: uppercase;
                            font-size: 2.8em;
                            text-align: center;
                            margin-top: 0;
                        }

                        .itemFullText{
                            h3{
                                text-align: center;
                                font-style: italic;
                                font-size: 1.2em;
                                margin-bottom: 20px;
                            }
                        }
                    }

                    .itemImageBlock {
                        margin: 0;
                        padding: 0;
                        height: 32vw;
                        background-color: @black;
                        
                        @media all and (min-width: 992px) {
                            margin-left: -4.166666666666667%;
                        }

                        >span {
							.opacity(1);
                        }
                    }
                }
            }
        }
    }
}

.dett-blog {

    .itemBody {
        z-index: 1;
        padding:0px;
        margin-left: 0px !important;
        text-align: left;


        &:after {
           display: none;
        }

        &.col-md-24 {
            margin-left: 0;
            
            &:after {
                content: none;
            }
        }

        h2.itemTitle {
            text-transform: uppercase;
            font-size: 2.8em;
            text-align: center;
            margin-bottom: 30px;
        }

        .itemFullText{
            h3{
                text-align: center;
                font-style: italic;
                font-size: 1.2em;
                margin-bottom: 20px;
               
            }
        }
        .addthis_inline_share_toolbox{
            text-align: center;
            margin-bottom:30px;
        }
    }

    .background-cover{
        margin-top: 90px;
        min-height: 35vw;
    }
}



.dett-offers {

    .itemBody {
        z-index: 1;
        padding:0px;
        margin-left: 0px !important;
        text-align: left;
        .image-cover{
                background-color: rgba(17,17,17,.4);
                background-blend-mode: multiply;
        }

        &:after {
           display: none;
        }

        &.col-md-24 {
            margin-left: 0;
            
            &:after {
                content: none;
            }
        }

        h2.itemTitle {
            text-transform: uppercase;
            font-size: 2.8em;
            text-align: center;
            margin-bottom: 30px;
        }

        .itemFullText{
            h3{
                text-align: center;
                font-style: italic;
                font-size: 1.2em;
                margin-bottom: 20px;
               
            }
        }
        .addthis_inline_share_toolbox{
            text-align: center;
            margin-bottom:30px;
        }
    }

    .background-cover {
        margin-top: 90px;
        min-height: 35vw;
        margin-left: 0!important;

        >span {
            .opacity(.75)!important;
        }
        
        .text {
            text-align: center;
            position: absolute;
            top: 50%;
            .transform(translateY(-50%));
            left: 0;
            right: 0;
            padding: 0 60px;

            .itemExtraFields{
                border:none;
                margin: 16px 0 60px 0;

                h3{
                     margin: 0;
                    padding: 0 0 30px 0;
                }
            }

            .vantaggi{
                color: white;
                margin: 0 0 20px;
            }
        }
    }
}






.dettaglio-camera {
    .itemBody {
        padding: 80px 60px;

        >h2 {
            margin-top: 0;
            font-size: 2.6em;
        }

        .details {
            text-align: center;
            margin-top: 20px;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid @gold;
            font-style: italic;
            font-size: 1.2em;
            width: 60%;
            margin-left: 20%;
            margin-right: 20%;
        }
    }
}

.room-services {
    overflow: hidden;

        &-detail {
            margin-bottom: 50px!important;

            .bg-white.absolute {
                >h2 {
                    font-size: 2.2em;
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                    border-bottom: 1px solid @gold;
                }
            }
        }

        .itemImageGallery {
            height: 40vw;
            margin: 0;
            z-index: 0!important;
            .transition(all ease-in-out .3s);
            .opacity(1);

            .background-cover {
                position: relative;

                .image-cover {
                    /*width: 100%!important;*/
                    // height: 17.5vw!important;
                }
            }
            

            .swiper-container-room {
                height: 100%;
                position: relative;
                /*width: 50%!important;*/

                .swiper-wrapper {
                    .swiper-slide {
                        background-color: @black;
                        position: relative;
                        overflow: hidden;

                        &:before {
                            position: absolute;
                            font-family: "icomoon";
                            content: "\e90a";
                            top: 50%;
                            left: 50%;
                            font-size: 6vw;
                            .transform(translate(-50%, -50%));
                            color: fade(@white, 60%);
                        }

                        a {
                            &:hover {
                                >span {
                                    .opacity(.75);
                                    .transform(scale(1.05));
                                }
                            }

                            >span {
                                .transition(all ease-in-out .3s);
                            }
                        }
                    }
                }

                .swiper-button {
                    width: auto;
                    position: absolute;
                    bottom: 20px;
                    left: 20px;

                    .swiper-button-next,
                    .swiper-button-prev {
                        color: #fff;
                        z-index: 5;
                        font-family: "icomoon";
                        position: relative;
                        width: 50px;
                        height: 50px;
                        background-color: transparent;
                        .box-shadow(inset 0 0 0 3px @white)
                    }

                    .swiper-button-next {
                        left: 70px!important;

                        &:before {
                            content: "\e907";
                            line-height: 50px;
                        }
                    }
                    .swiper-button-prev {
                        left: 0;
                        margin-top: -50px;

                        &:before {
                            content: "\e906";
                            line-height: 50px;
                        }
                    }
                }
            }
        }
    }

    .ristorante {
    overflow: hidden;

        &-detail {
            margin-bottom: 50px!important;

            .bg-white.absolute {
                >h2 {
                    font-size: 2.2em!important;
                    padding-bottom: 20px;
                    margin-bottom: 20px;
                    border-bottom: 1px solid @gold;
                }
            }
        }

        .room-services {
            margin-top: 50px;
            margin-bottom: 50px;
        }

        .swiper-container-ristorante {
            height: 35vw;
            position: relative;
			padding: 0;

            .swiper-wrapper {
                .swiper-slide {
                    background-color: @black;
                    position: relative;
                    /*margin-left: 5%;*/

                    &:before {
                        position: absolute;
                        font-family: "icomoon";
                        content: "\e90a";
                        top: 50%;
                        left: 50%;
                        font-size: 6vw;
                        .transform(translate(-50%, -50%));
                        color: fade(@white, 60%);
                    }

                    a {
                        &:hover {
                            >span {
                                .opacity(.75);
                                .transform(scale(1.05));
                            }
                        }

                        >span {
                            .transition(all ease-in-out .3s);
                        }
                    }
                }
            }
            
            .swiper-button {
                width: auto;
                position: absolute;
                bottom: 20px;
                left: 20px;

                .swiper-button-next,
                .swiper-button-prev {
                    color: #fff;
                    z-index: 5;
                    font-family: "icomoon";
                    position: relative;
                    width: 50px;
                    height: 50px;
                    background-color: transparent;
                    .box-shadow(inset 0 0 0 3px @white);
                    text-align: center;
                }

                .swiper-button-next {
                    left: 70px!important;

                    &:before {
                        content: "\e907";
                        line-height: 50px;
                    }
                }
                .swiper-button-prev {
                    left: 0;
                    margin-top: -50px;

                    &:before {
                        content: "\e906";
                        line-height: 50px;
                    }
                }
            }


            /*.swiper-button {
            //     width: auto;
            //     position: absolute;
            //     bottom: 20px;
            //     left: 20px;

                .swiper-button-next,
                .swiper-button-prev {
                    top: 90%;
                    font-family: "icomoon";
                    color: white;
                    z-index: 5;
                    font-family: "icomoon";
                    width: 50px;
                    height: 50px;
                    font-size: 2em;
                    font-weight: bold;
                    background-color: transparent;
                    .box-shadow(inset 0 0 0 3px @gold)
                }

                .swiper-button-next {
                            right:30px;
                            padding-left: 10px;

                    &:before {
                        content: "\e907";
                        line-height: 50px;
                    }
                }

                .swiper-button-prev {
                       right:100px;
                       padding-left:5px; 
  
                    &:before {
                        content: "\e906";
                        line-height: 50px;
                    }
                }
            }*/
        }

        .itemImageGallery {
            height: 30vw;
            margin: 0;
            z-index: 0!important;
            .transition(all ease-in-out .3s);
            .opacity(1);

            a.background-cover {
                position: relative;

                .image-cover {
                    width: 100%!important;
                    // height: 17.5vw!important;
                }
            }
        }
    }