@media all and (max-width: 1680px) {
	#content .content-text .container-full .article-text #k2Container {
		.itemBody {
			margin-left: 0;

			h2.itemTitle {
				font-size: 2em;
			}
		}

		.itemImageBlock {
			height: 35vw;
		}
	}

	.list-blog .container-full .swiper-wrapper .swiper-slide .text h2 {
		margin-bottom: 20px;
		padding-bottom: 20px;
	}

	.list-blog .container-full .swiper-wrapper .swiper-slide.background-cover {
		height: 22.5vw;
	}

	.list-offers .container-full .swiper-wrapper .swiper-slide {
		/*height: 28vw;*/
		height: auto;

		>.background-cover {
			height: 18vw;
		}
	}


	.cta {
		>p {
			&:first-child {

				&:before {
					left: 5%;
				}
			}

			&:last-child {
				&:before {
					right: 5%;
				}
			}

			&:first-child {
				&:hover {
					&:before {
						left: 2.5%;
					}
				}
			}
			&:last-child {
				&:hover {
					&:before {
						right: 2.5%;
					}
				}
			}

			&:hover {
				a {
					letter-spacing: .05em;
				}
			}
		}
	}


	.list-rooms ul li .box {
		width: 80%;
		left: 10%;

		h2 {
			font-size: 1.5em;
			margin-top: 20px;
		}
	}

	.room-services .absolute.bg-white {
		padding: 20px;

		>h2 {
    		font-size: 1.8em;
		}
	}

	.list-risto .tab-control .nav-stacked>li>a {
		line-height: 1em;

		span:after {
			bottom: 20%;
		}
	}

	.list-risto .tab-content .tab-pane .text {
		top: 15%;
	    width: 55%;
	}

}
@media all and (max-width: 1440px) {
	
	.list-spa .tab-control .nav-stacked>li>a {
    font-size: 1.3em;
	}
	
	
	.list-accomodation .tab-control .nav-stacked>li>a {
		font-size: 2em;
	}
	.list-accomodation-4 .tab-control .nav-stacked>li>a {
		font-size: 2em;
	}
	.list-castle .tab-control .nav-stacked>li>a {
		font-size: 2vw;
	}

	.list-castle .btn span p {
		display: none;
	}


	.lista-attivita li .box {
		padding: 30px 15px;
	}

	.list-blog .container-full .swiper-wrapper .swiper-slide.background-cover {
		height: 30vw;
	}

	

	.cta {
		>p {
			font-size: .9em;

			&:first-child {

				&:before {
					left: 3%;
				}
			}

			&:last-child {
				&:before {
					right: 3%;
				}
			}

			&:first-child {
				&:hover {
					&:before {
						left: 1%;
					}
				}
			}
			&:last-child {
				&:hover {
					&:before {
						right: 1%;
					}
				}
			}

			&:hover {
				a {
					letter-spacing: .04em;
				}
			}
		}
	}

	.list-rooms ul li {
		height: 40vw;

		.box {
		    width: 100%;
		    left: 0;
		    height: 18vw;
		}
	}

	.room-services .absolute.bg-white {
	    padding: 20px 15px;

		>h2 {
			font-size: 1.5em;
		}

		p, ul {
			font-size: .9em;
			line-height: 1.5em;
		}
	}
	
	.list-risto .tab-control .nav-stacked>li>a {
		line-height: 1em;
		padding: 20px 30px;
		font-size: 1.6em;

		span:after {
			bottom: 20%;
		}
	}
	
	.list-risto .tab-content .tab-pane .text {
		top: 15%;
	    width: 60%;
	}

	.list-article .k2ItemsBlock .container-full>ul>li .text {
		width: 80%;
	}

}
@media all and (max-width: 1280px) {
	#content .content-text .container-full .article-text #k2Container .itemImageBlock {
	    height: 59vw;
	}
	.lista-attivita li .box {
	    padding: 15px;

		.wrap {
			font-size: .8em;

			h2  {
				font-size: 1.6em;
			}
		}
	}

	.list-offers .container-full .swiper-wrapper .swiper-slide {
		/*height: 30vw;*/
		height: auto;
	}

	.review .item blockquote {
		padding-top: 25px;
		padding-bottom: 25px;

		h2 {
			font-size: 2em;
			line-height: 1.2em;
			padding-bottom: 0;
		}
	}

	/*.room-services {
		height: 52vw;
	}*/

	.cta {
		>p {
			font-size: .75em;

			&:first-child {

				&:before {
					left: 2%;
				}
			}

			&:last-child {
				&:before {
					right: 2%;
				}
			}

			&:first-child {
				&:hover {
					&:before {
						left: .5%;
					}
				}
			}
			&:last-child {
				&:hover {
					&:before {
						right: .5%;
					}
				}
			}

			&:hover {
				a {
					letter-spacing: .035em;
				}
			}
		}
	}

	.list-risto .tab-content .tab-pane .text {
		top: 10%;
		font-size: 0.9em;
		line-height: 1.3em;
		padding: 20px;
	}

	.list-article.altre-attivita .k2ItemsBlock .container-full>ul>li:last-child, 
	.list-article.servizi-family .k2ItemsBlock .container-full>ul>li:last-child {
		height: 25vw;
	}

	.list-article .k2ItemsBlock .container-full>ul>li .text {
		width: 95%;

		.wrap_text {
			padding: 15px;
		}
	}
}
@media all and (max-width: 1200px) {
	.list-rooms {
		ul li {
			&:nth-child(3n+2) {
		    	border-right: none; 
			}

			&:nth-child(2n+2) {
		    	border-left: 10px solid @white;
			}

			.box { 
				h2 {
				    font-size: 1.3em;
				    margin-top: 15px;
				}
			}
		}

		&.camere {
			ul li:last-child {
				width: 100%;
			}
		}
	}
	
	.room-services {
		.flexbox(column);
	    height: 50vw;

		.bg-white {
			position: relative;
			height: auto;
			font-size: .9em;
			padding: 30px;

			ul {
				-webkit-column-count: 2;
				-moz-column-count: 2;
				column-count: 2;
				line-height: 1.35em;
			}
		}
	}

	.list-article .k2ItemsBlock .container-full>ul>li .text {
		font-size: 1.25vw;
		line-height: 1.6vw;
	}
	
	.ristorante .swiper-container-ristorante .swiper-wrapper .swiper-slide {
		margin-left: 0;
	}

	.list-accomodation .tab-control .nav-stacked>li>a {
		font-size: 1.6em;
	}
	.list-castle .tab-control .nav-stacked>li>a {
		font-size: 1.6em;
	}

	.list-accomodation-4 .tab-control .nav-stacked>li>a {
		font-size: 1.6em;
	}

	.blocco-full .text {
		font-size: .85em;
	}
}

@media all and (max-width: 1100px) {
	.swiper-container-background .swiper-slide .claim img {
		display: none;
	}
}

@media all and (max-width: 1023px) {
	header {
		.navbar-default {
			.opacity(0);
			/*.box-shadow(0 0 20px -10px @black);*/
			border-right: 1px solid fade(@darkgrey, 20%);

			.toggle-sidebar {
				position: absolute;
				border-right: 1px solid fade(@darkgrey, 20%);
				border-bottom: 1px solid fade(@darkgrey, 20%);
			}
		}
	}

	.go-down {
		top: 70vh;
	}

	.swiper-container-background {
		height: 70vh;

		/*.swiper-button {
			right: 100px;
			bottom: 110px;
		}*/
	}

	.blocco-full {
		height: 35vmax;

		&.testo-destra .wrap-text {
			border-left: 50px solid @beige;
		}

		.text>h2 {
			font-size: 2em;
		}
	}

	.list-offers .container-full .swiper-wrapper .swiper-slide .text>a {
		margin: 20px auto;
	}

	.room-services {
		height: auto;

		.background-cover {
			height: 25vw;
		}
	}

	.room-services .itemImageGallery {
		height: 50vw;

		.swiper-container-room {
			height: 50vw;

			.background-cover {
				height: 50vw;
			}
		}
	}

	.room-services .absolute.bg-white {
		position: relative;
	}

	.ristorante {
		/*height: 50vw;*/

		.swiper-container-ristorante {
			height: 50vw;

			.background-cover {
				height: 50vw;
			}
		}
	}

	.mappainterattiva {
		height: auto;
		margin-bottom: 0;

		#interactive-map {
			height: 64vw;
		}
	} 
	.poi .container-poi .table tr td {
		padding: 0px 20px;
	}

	.list-article .k2ItemsBlock .container-full>ul>li {
		width: 100%;
	    height: 45vw;
		margin-right: 0;

		&:nth-child(2n+1) {
			margin-right: 0;
		}

		.text {
			font-size: 1em;
			line-height: 1.2em;

			>h2 {
				font-size: 2em;
			}

			.wrap_text {
			    border-width: 50px
			}
		}
	}

	.list-spa {
		.tab-content .tab-pane .text {
			top: 10%;
			width: 80%;
			padding: 20px;
			font-size: .9em;
		}

		.tab-control .nav-stacked>li {
			&.active {
				left: -5%;
			}
			>a {
				font-size: 1em;
				padding: 0 15px;
			}
		}
	}

	.list-risto {
		height: 39vmax;

		.tab-content .tab-pane .text {
			top: 10%;
			width: 80%;
			padding: 15px;
			font-size: .85em;
		}

		.tab-control .nav-stacked>li {
			height: 13vmax;

			&.active {
				left: -5%;
			}

			>a {
				font-size: 1.2em;

				>span {
					line-height: 1.4em;
				}
			}
		}
	}

	.list-article .k2ItemsBlock .container-full>ul>li .text .wrap_text ul {
		padding-left: 20px;
	}

	.list-article.attivita .k2ItemsBlock .container-full>ul>li:last-child {
		.text {
			top: 20%;
		}

		>a {
			bottom: 20px;
		}
	}

	div.catItemIsFeatured:before, 
	div.userItemIsFeatured:before, 
	div.itemIsFeatured:before {
		content: none;
	}

	#content .content-text .container-full .article-text #k2Container.itemIsFeatured .itemBody {
		width: 100%;
	}

	.tablet,
	.mobile {
		&.push-right {
			left: auto;
		}
		header .navbar-default,
		.no-background header .navbar-default:hover, header .navbar-default.affix:hover {
			background-color: @white;
		} 
	}

	header .navbar-default .navbar-nav {
		overflow-y: scroll;
	}

	.swiper-container-background .swiper-slide .text {
		left: 10%;
		text-align: center;
		width: 80%;
		font-size: 2.6em;
	}
}

@media all and (max-width: 991px) {
	/*.swiper-container-background .swiper-button {
		bottom: 30px;
	}
*/

	.flexbox-center-y.itemView {
		.flexbox(column);
	}

	#content .content-text .container-full .article-text #k2Container {
		align-items: unset;

		.itemBody {
			position: relative;
		    padding: 40px 90px 40px 40px;

		    &:after {
		    	right: 0;
		    	width: 50px;
		    }
		}

		.itemImageBlock {
			height: 40vw;
		}
	}

	footer .container .logo-footer {
		top: -15px;
	}

	.list-accomodation,
	.list-accomodation-4 {
		height: 48vw;

		.tab-control .nav-stacked>li {
			height: 12.1vw;

			>a {
				font-size: 1.2em;
				line-height: 0;
				padding: 30px 50px;
			}
		}
	}

		.list-castle {
		height: 48vw;

		.tab-control .nav-stacked>li {
			height: 12.1vw;

			>a {

				line-height: 0;
				padding: 30px 50px;
			}
		}
	}
	.list-castle .btn span{
		margin-top: 0!important;
	}

	.blocco-full {
		.flexbox(column);
		height: auto;

		&.testo-destra {
			.flexbox(column);

			.wrap-text {
				border-left: none;
			}
		}

		.wrap-text {
			height: 20vw;

			.text {
				>h2 {
					margin-top: 10px;
				}
				>h3 {
					margin-bottom: 15px;
				}
			}
		}

		.background-cover {
			height: 30vw;
		}
	}

	.lista-attivita li {
		&:nth-child(1) {
			height: 50vw;
		}
		&:nth-child(2),
		&:nth-child(3),
		&:nth-child(4) {
			height: 24.5vw;
		}

		&:nth-child(2) {
			.box {
				left: 50%;
			}
		}
	}

	.cta>p {
		font-size: .6em;
	}

	.list-rooms ul {
		li {
			.box {
				h2 {
					font-size: 1.2em;
				    margin-top: 10px;
				}

				.wrap {
				    padding: 10px;

					.details {
						padding-bottom: 6px;
						margin-bottom: 10px;
					}

					a.btn {
						height: 30px;
						line-height: 28px					
					}
				}
			}
		}
	}
}

@media all and (max-width: 850px) {
	.lista-attivita li {
		&:nth-child(1) {
			height: 60vw;
		}
		&:nth-child(2),
		&:nth-child(3),
		&:nth-child(4) {
			height: 29.5vw;
		}

		.box {
			width: 100%;

			.wrap {
				h2 {
					margin-bottom: 7px;
				    font-size: 1.5em;
				}

				a.btn {
					height: 30px;
					line-height: 28px;
				}
			}
		}
	}
}

@media all and (max-width: 767px) {

	.list-castle .btn span {
    	margin-top: 2vmax!important;
	}

	div.mod-languages li {
	    margin-left: 2px!important;
	    margin-right: 2px!important;
	}

	.go-down {
		height: 30px;
	    width: 30px;
	    font-size: .9em;
	    margin-top: -50px;
	    .box-shadow(inset 0 0 0 2px @white);
	}

	.swiper-container-background .swiper-button {
	    width: 70px;
	    bottom: 20px;
	    right: 20px;

	    .swiper-button-next {
	    	margin-left: 10px;
	    }

	    .swiper-button-prev,
	    .swiper-button-next {
	    	width: 30px;
    	    height: 30px;
    	    .box-shadow(inset 0 0 0 2px @white);
    	    font-size: .9em;

    	    &:before {
    	    	line-height: 30px;
    	    }
	    }
	}

	#content .content-text .container-full .article-text #k2Container .itemImageBlock {
		height: 65vw;
	}


	#content .content-text .container-full .article-text #k2Container .itemBody h2.itemTitle {
		font-size: 1.6em;
	}

	.list-spa,
	.list-risto,
	.list-accomodation,
	.list-accomodation-4,
	.list-castle {
		height: auto;

		.flexbox(column-reverse);

		.tab-content {
			height: 50vw;
						.tab-pane {
				padding-top: 30px;
				padding-bottom: 30px;
				height: 24vh;
				
			}
		}

		.tab-control {
			.nav-stacked {
				>li {

					&.active {
						left: auto;

						>a {
							background-color: @darkgrey!important;
							color: @white;
						}
					}

					>a {
						line-height: 9vw;
						padding: 0 50px;
					}
				}
			}
		}
	}

	.list-risto,
	.list-spa {
		.tab-control .nav-stacked>li {
		    height: 6vmax;

			>a {
				line-height: 6vmax;

				>span {
					line-height: 6vmax;
				}
			}
		}

		.tab-content {
			height: auto;

			.tab-pane {
				padding-top: 30px;
				padding-bottom: 30px;

			}
		}
	}

	.list-risto .tab-control .nav-stacked>li>a>span br {
		display: none;
	}

	.ristorante {
		height: auto;
	}

	.ristorante .swiper-container-ristorante .swiper-button .swiper-button-next, .ristorante .swiper-container-ristorante .swiper-button .swiper-button-prev {
		top: auto;
		bottom: 20px;
	}

	.ristorante .swiper-container-ristorante {
		padding-left: 0;
		padding-right: 0;
	}

	.blocco-full {
		.wrap-text {
		    height: 40vw;

		    .text {
		    	>h2 {
		    		font-size: 1.6em;
		    	}
		    	>h3 {
		    		font-size: 1em;
		    	}
		    }
		}

		.background-cover {
			height: 50vw;
		}
	}

	.lista-attivita {
		li {
			&:nth-child(1), 
			&:nth-child(2), 
			&:nth-child(3), 
			&:nth-child(4) {
				width: 100%;
				float: none;
			    height: 60vw;
			    margin: 0;
			    margin-bottom: 20px;
			}
		}
	}

	.list-blog {
		>h2 {
			font-size: 1.8em;
		}
		>h3 {
			font-size: 1em;
		}
		.k2ItemsBlock {
			.swiper-container-blog-home {
				.swiper-wrapper {
					.swiper-slide {
						height: 60vw;

						.text {
							width: 90%;

							h2 {
								font-size: 1.6em;
								margin-bottom: 10px;
								padding-bottom: 10px;
							}
						}

						>a {
							bottom: 20px;
						}
					}
				}
			}
		}	
	}

	.list-offers {
		&.offerte-home {
			>h2 {
				font-size:1.8em;
			}
			>h3 {
				font-size:1em;
			}
		}

		.container-full {
		    padding: 0 30px!important;

			.swiper-wrapper {
				.swiper-slide {
					>.background-cover {
						height: 40vw;
					}

					.text {
						>a {
							width: 80%;
						}
					}
				}
			}
		}
	}

	.review {
		.item {
			width: 100%;
			padding-top: 0px;
			padding-bottom: 0px;		

			blockquote {
				h2 {
					font-size: 1.5em;
				}
			}
		}
	}

	footer .container {
		.logo-footer img {
			width: 240px;
		}

		.dati-hotel {
			>p:nth-last-child(1),
            >p:nth-last-child(2) {
                display: block;
            }

            >p:nth-last-child(1) {
                margin-left: 0;
            }
		}
	}

	div.itemFullText {
		font-size: .9em;
	}

	.room-services {
		.background-cover {
			height: 50vw;
		}

		.absolute.bg-white {
			padding: 30px 15px;

			p, ul {
				font-size: 1em;
			}

			ul {
				-webkit-column-count: 1;
				-moz-column-count: 1;
				column-count: 1;
			}
		}
	}

	.cta>p {
		width: 100%;

		&:first-child {
			border-bottom: 1px solid fade(@white, 25%);
		}

		>a {
			&:after {
				content: none;
			}
		}
	}

	.list-rooms ul li {
		height: 70vw;
		border: none!important;
	    border-bottom: 10px solid @white!important;



		.box {
			height: 30vw;
		}
	}
	
	header .navbar-default .navbar-nav>li>a {
		padding-bottom: 3px;
		padding-top : 3px;
	}

	.mappainterattiva #interactive-map {
		height: 70vw;
	}

	.list-article.attivita .k2ItemsBlock .container-full>ul>li:hover>a, 
	.mobile .list-article.attivita .k2ItemsBlock .container-full>ul>li>a, 
	.tablet .list-article.attivita .k2ItemsBlock .container-full>ul>li>a {
		bottom: 30px;
	}

	.list-article.altre-attivita .k2ItemsBlock .container-full>ul>li:last-child, 
	.list-article.servizi-family .k2ItemsBlock .container-full>ul>li:last-child {
		height: 45vw;
	}
	
	.social-menu .conde img {
		display: none;
	}

	header .navbar-default .navbar-nav {
		height: 60vh;
		padding-bottom: 100px;
		.visible-xs{
			padding-top:30px;
			padding-bottom: 240px;
		}
	}

	.toggle-sidebar {
		background-color: @white;

		&.is-active {
			background-color: transparent!important;
		} 
	}

	.swiper-container-country .swiper-button {
		.swiper-button-prev {
			left: 0!important;
		}
		.swiper-button-next {
			right: 0!important;
		}
	}

	/*aggiunta logo background*/
	.mobile,
	.tablet {
		.swiper-container-background {
			position: relative;

			&:before {
				content: "";
				background-image: url('../../../images/logo.png');
				position: absolute;
				top: 0%;
				left: 0%;
				/*.transform(translate(-50%, -50%));*/
				background-repeat: no-repeat;
				background-position: center 40%;
				width: 100%;
				height: 100%;
				text-align: center;
			    z-index: 2;
			}

			.swiper-slide {
				background-color: @black;

				>span {
					.opacity(.7);
				}
			}
		}
	}

	.social-menu {
		display: none;
	}

	header .navbar-default .navbar-nav {
		height: 90vh;
	}
}



@media all and (max-width: 600px) {
	#content .content-text .container-full .article-text #k2Container .itemImageBlock {
		height: 90vw;
	}

	.room-services .bg-white {
		padding: 30px 10px;
		
		>h2 {
			font-size: 1.8em;
		}

		ul {
			-webkit-column-count: 1;
			-moz-column-count: 1;
			column-count: 1;
		}
	}
}


@media all and (max-width: 461px) {
	.hamburger {
		padding: 11px 15px!important;
	}

	header {
		.navbar-default {
			width: 100%;
			border: none;
			left: 0!important;

			.toggle-sidebar {
				right: -71px;

				.no-background & {
					right: -71px;
				}
			}

			.container-fluid {
				background-color: @white;

				.navbar-brand {
					padding:0;

					img.img-affix {
						margin: 10px auto;
					    width: 240px;
					}
				}
			}

			&.affix {
				left: -100%!important;
			}

			&.toggle-menu {
				left: 0!important;
			}

			.toggle-sidebar {
				border-right: 1px solid fade(@darkgrey, 20%);
				border-bottom: 1px solid fade(@darkgrey, 20%);

				&.is-active {
					right: 0;
					border: none;
				}
			}
		}
	}

	.list-offers>h3 {
		left: auto;
		.transform(translateX(0));
		display: block;

		&:before {
			width: 90%;
		}
	}

	.dett-offers {
		.flexbox(column);

		/*.itemImageBlock {
			height: auto!important;
		}*/

		.itemBody {
			padding: 20px!important;
		}

		/*.background-cover .text {
		    padding: 0 20px!important;
			top: 25px!important;
			position: relative!important;
		}*/

		.background-cover .text .itemExtraFields {
			margin: 16px 0 20px 0;

			.btn {
				padding: 0 25px;
			}
		}
	}


	/*.flexbox-center-y.itemView {
		.flexbox(column);
	}*/

	.list-blog .k2ItemsBlock .swiper-container-blog-home .swiper-wrapper .swiper-slide {
		height: 70vw;
	}

	#content .content-text .container-full .article-text #k2Container .itemBody {
		padding: 25px;

		&:after {
			content: none;
		}
	}

	.dett-blog .background-cover {
		margin-top: 0;
		min-height: 60vw;
	}

	.list-blog.blog-home .container-full {
		padding: 0 30px;
	}

	.cta>p a {
		font-size: 1.4em;
	}

	.dett-offers .background-cover .text {
		padding: 0 10px;
	}
}

@media all and (max-width: 401px) {
	header .navbar-default .container-fluid .navbar-brand img.img-affix {
		width: 210px;
	}

	.list-rooms {
		ul {
			li {
				.box {
					h2 {
						font-size: 1em;
						padding: 0 1%;
					}

					.wrap {
						.details {
							font-size: .85em;
						}
					}
				}
			}
		}
	}

	#content .content-text .container-full .article-text #k2Container .itemBody h2.itemTitle {
		font-size: 1.3em;
	}

	.blocco-full .wrap-text {
		height: 60vw;
	}

	.list-blog .k2ItemsBlock .swiper-container-blog-home .swiper-wrapper .swiper-slide {
		height: 80vw;
	}

	header .navbar-default .navbar-nav {
		height: 90vh;
	}

	.swiper-container.didascalia .swiper-wrapper .swiper-slide .claim {
		font-size: .8em;
	}


	#content .content-text .container-full .article-text #k2Container.dett-offers .itemImageBlock {
		height: 70vh;
	}
}

@media all and (max-width: 331px) {
	header .navbar-default .navbar-nav {
		height: 90vh;
		padding-bottom: 97px;
	}

	.swiper-container-background:before {
		.transform(scale(.9));
	}
}

@media all and (max-width: 331px) and (max-height: 491px) {
	header .navbar-default .navbar-nav {
		height: 90vh;
		padding-bottom: 40px;
	}
}

.mobile {
	.navtab-mobile,
	.list-article.ui-accordion {
		h4.ui-accordion-header {
			position: relative;
			font-size: 1.2em;
			background-color: @white;
			color: @darkgrey;
			margin: 0;
		    padding: 15px 40px 15px 15px;
			border-bottom: 1px solid fade(@darkgrey, 30%);
			outline: none;
			.transition;

			&:first-child {
				border-top: 1px solid fade(@darkgrey, 30%);
			}

			&:before {
				position: absolute;
				right: 15px;
				color: @gold;
				.transition;
			}

			&:after {
				content: "";
				position: absolute;
				left: 0;
				top: 0;
				height: 100%;
				width: 10px;
				background-color: fade(@beige, 0%);
				.transition;
			}

			&-active {

				&:before {
					.transform(rotate(135deg));
				}

				&:after {
					background-color: fade(@beige, 100%);
				}
			}
		}
		.ui-accordion-content {
			padding: 20px;
			background-color: @white;
			border-bottom: 1px solid fade(@darkgrey, 30%);

			.background-cover {
			    height: 50vw;
			}

			.btn {
				white-space: normal;
				line-height: 1.4em;
				height: auto;
			}
		}
	}

	.navtab-mobile {
		.ui-accordion-content .background-cover {
			margin-bottom: 15px;
		}
	}
}

@media all and (orientation: landscape) {
	.mobile {
		header .navbar-default {
			.toggle-sidebar {
				background-color: @white!important;
			}

			.navbar-nav {
				height: 60vh;
			    padding-bottom: 50px;
			}
		}

		#dashboard {display: none!important;}

		.navbar-brand {
			padding: 0;
		}

		.no-background header .navbar-default .navbar-brand img.img-affix, 
		header .navbar-default.affix .navbar-brand img.img-affix {
			width: 240px;
			margin: 10px auto;
		}
	}

	.mobile {
		.list-accomodation,
		.list-risto,
		.list-spa,
		.list-castle {
			.tab-control .nav-stacked>li {
				height: 12vh;

				a {
					line-height: 12vh;
				}
			}

			.tab-content {
				height: 60vh;
			}
		}

		.blocco-full {
			.wrap-text {
				height: 40vh;
			}
			.background-cover {
				height: 50vh;
			}
		}

		.lista-attivita li {
			height: 80vh;
		}

		.list-blog .k2ItemsBlock .swiper-container-blog-home .swiper-wrapper .swiper-slide {
			height: 60vh;
		}

		.list-offers .container-full .swiper-wrapper .swiper-slide>.background-cover {
			height: 50vh;
		}

		.room-services .background-cover {
			height: 60vh;
		}

		.list-rooms ul li {
			height: 70vh;

			.box {
				height: 30vh;
			}
		}

		.room-services .itemImageGallery .swiper-container-room {
			height: 75vh;

			.background-cover {
				height: 75vh;
			}
		}
		
		.mappainterattiva #interactive-map {
			height: 70vh;
		}

		.ristorante .swiper-container-ristorante {
			height: 60vh;
		}

		.flexbox-center-y.itemView {
			.flexbox(column);
		}

		#content .content-text .container-full .article-text #k2Container .itemBody {
			padding: 40px;
		}

	}

}

@media all and (orientation: landscape) and (max-height: 331px) {
	.mobile,
	.tablet {
		header .navbar-default {
			.navbar-nav {
				height: 45vh;
			}
		}
	}
}

@media all and (max-width: 1200px) and (min-width: 1024px) {
	.room-services .bg-white {
		height: auto;
	}
}

.tablet .list-accomodation .tab-pane.background-cover {
    height: 36vw;
}
.tablet .list-accomodation-4 .tab-pane.background-cover {
    height: 48w;
}

.tablet .list-castle .tab-pane.background-cover{
    height: 48vmax;
}

.tablet .list-risto .tab-pane.background-cover,
.tablet .list-spa .tab-pane.background-cover {
    height: 40vmax;
}
.tablet .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.tablet {
	.swiper-container-background .swiper-button {
		bottom: 80px;
	}
	.go-down {
		margin-top: -130px;
	}
	header .navbar-default .navbar-nav>li>a {
		padding-top: 3px;
		padding-bottom: 3px;
	}	
}