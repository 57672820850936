/* -------------------------------------------------------------------------- */
/* --- TEMPLATE STANDARD ---------------------------------------------------- */
/* -------------------------------------------------------------------------- */

/* --- MIXIN ---------------------------------------------------------------- */
.box-shadow(@string) {
    box-shadow: @string ;
    -moz-box-shadow: @string;
    -webkit-box-shadow: @string;
}

.box-shadow(@string,@string1) {
    box-shadow: @string,@string1;
    -moz-box-shadow: @string,@string1;
    -webkit-box-shadow: @string,@string1;
}

.text-shadow(@string) {
    text-shadow: @string;
    -moz-text-shadow: @string;
    -webkit-text-shadow: @string;
}

.text-shadow-double(@string, @string2) {
    text-shadow: @string, @string2;
    -moz-text-shadow: @string, @string2;
    -webkit-text-shadow: @string, @string2;
}

.opacity(@alpha:0, @palpha:percentage(@alpha)){
    -webkit-opacity: @alpha;
    -moz-opacity: @alpha;
    opacity: @alpha;
    filter: alpha(opacity=@palpha); /* For IE8 and earlier */
    zoom: 1;
}

.transition(@string: all ease-in-out .3s) {
    transition: @string;
    -moz-transition: @string;
    -webkit-transition: @string;
}

.transition-multiple(@string, @string2) {
    transition: @string, @string2;
    -moz-transition: @string, @string2;
    -webkit-transition: @string, @string2;
}

.transform(@string) {
    transform:  @string;
    -moz-transform: @string;
    -webkit-transform: @string;
}

.border-radius(@radius) {
    border-radius: @radius;
    -moz-border-radius: @radius;
    -webkit-border-radius: @radius;
}

.keyframes(@name, @arguments) {
	@-moz-keyframes @name {@arguments();}
	@-webkit-keyframes @name {@arguments();}
	@keyframes @name { @arguments(); }
}

.animation(@arguments) {
	-webkit-animation: @arguments;
	-moz-animation: @arguments;
	animation: @arguments;
}

/* --- COMMON CLASS SITO BASE ----------------------------------------------- */
.background-cover {
  position: relative;
  height: 100%;
  outline: none;
  
  .image-cover {
    position: absolute;
    left:0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    z-index: 0;
    }
}


.align-right {text-align: right;}
.align-left {text-align: left;}
.align-center {text-align: center;}

.absolute {position: absolute;}
.relative {position: relative;}
.fixed {position: fixed;}

.row.item {
    min-height: 200px;
    position: relative;
}

.no-padding {
	padding: 0;
}

.no-alternate {
  .row {
    .background-cover {
        position: absolute;
        right: 0;
        width: 50%;
        height: 100%;
    }
  }
}


.alternate {
  .row {

    .background-cover {
        position: absolute;
        right: 0;
        top: 0;
        width: 50%;
        height: 100%;
    }

        &.item:nth-of-type(2n+1) .background-cover {
          right: 0;
          left: auto;
        }
        &.item:nth-of-type(2n+2) .text {
            float: right;
        }
        &.item:nth-of-type(2n+2) .background-cover {
            left: 0;
        }
    }
}


.container-full {
    margin: 0px 4%;
    max-width: 1600px;

    @media all and (min-width: 1600px) {
        margin: 0 5%;
        width: 90%;
        max-width: none;
    }

    @media all and (max-width: 767px) {
        margin: 0px 3%;
    }

}

/*
FLEX-WRAP--->whether items wrap to the next row (only applies if combined width of items is greater than container's)
-----------------------------------------------------------------------
JUSTIFY-CONTENT---> alignment along the x axis
-----------------------------------------------------------------------
ALIGN-ITEMS--->alignment along the y axis
-----------------------------------------------------------------------
ALIGN-CONTENT--->only applies if there is more than one row of items
*/
.flexbox(@flex-direction: row, @flex-wrap: nowrap, @justify-content: flex-start, @align-items: stretch, @align-content: stretch) {
    display: -moz-box;
    display: -webkit-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-flex-direction: @flex-direction;
    -moz-flex-direction: @flex-direction;
    -ms-flex-direction: @flex-direction;
    flex-direction: @flex-direction;
    -webkit-flex-wrap: @flex-wrap;
    -moz-flex-wrap: @flex-wrap;
    -ms-flex-wrap: @flex-wrap;
    flex-wrap: @flex-wrap;
    -webkit-justify-content: @justify-content;
    -moz-justify-content: @justify-content;
    -ms-justify-content: @justify-content;
    justify-content: @justify-content;
    -webkit-align-items: @align-items;
    -moz-align-items: @align-items;
    -ms-align-items: @align-items;
    align-items: @align-items;
    -webkit-align-content:  @align-content;
    -moz-align-content:  @align-content;
    -ms-align-content:  @align-content;
    align-content:  @align-content;
}

.flexbox {
    .flexbox;
}

.flexbox-center-y {
    .flexbox(@align-items: center);
}


/* -------------------------------------------------------------------------- */
/* --- END TEMPLATE STANDARD ------------------------------------------------ */
/* -------------------------------------------------------------------------- */

/*FONT*/
@serif: "Playfair Display", sans-serif;


/* --- COLOR and BACKGROUND ------------------------------------------------- */
@white           : #ffffff;
@black           : #000000;
@darkgrey        : #333333;
@grey            : #ebebe9;
@beige           : #dcd3ba;
@gold            : #98844f;
@bg-main         : #efece3;

.bg-black {
    background-color: @black;
    color: @white;
}
.bg-darkgrey {
    background-color: @darkgrey;
    color: @white;
}
.bg-grey {
    background-color: @grey;
    color: @black;
}
.bg-beige {
    background-color: @grey;
    color: @white;
}
.bg-gold {
    background-color: @gold;
    color: @white;
}
.bg-white {
    background-color: @white;
    color: @black;
}

/* --- BUTTON --------------------------------------------------------------- */
.btn() {
    font-size: .8em;
    font-weight: 400;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 0;
    text-align: center;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    white-space: nowrap;
    padding: 0 30px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 44px;
    line-height: 40px;
    .border-radius(0);
    .transition(all ease-in-out .3s);
    position: relative;
}

.btn-color(@background, @color: #fff) {
    color: @color;
    background-color: @background;
    border: 1px solid @background;
    .transition(all ease-in-out .3s);

    &:hover,
    &:focus {
        color: #fff;
        background-color: darken(@background, 12%);
        border: 1px solid darken(@background, 12%);
        .transition(all ease-in-out .3s);
    }

    &-border {
        color: @darkgrey;
        border: 1px solid @darkgrey;
        background: transparent;

        &:hover,
        &:focus {
            color: #fff;
            border: 1px solid @background;
            background: @background;

            & when (@background = @white) {
                background: transparent;
            }
        }
    }
}

.btn {
    .btn();

    &.btn-white {
        .btn-color(@white, @black);
        border: 3px solid @white;


        &:hover {
            .btn-color(transparent, @white);
            border: 3px solid @white!important;
            background: transparent;
            color: @white!important;
        }
    }

    &.btn-transparent {
        .btn-color(transparent);
        border: 3px solid @white;
        color: @white!important;


        &:hover
        &:focus  {
            .btn-color( @white);
            border: 3px solid @white!important;
            background: transparent;
            color: @gold!important;
        }
    }

    &.btn-gold {
        .btn-color(@gold, @white);
        color: @white!important;
        border: 3px solid @gold;


        &:hover {
            .btn-color(transparent, @gold);
            border: 3px solid @gold!important;
            background: transparent;
            color: @gold!important;
        }
    }

    &.btn-black {
        .btn-color(@darkgrey, @white);
        color: @white!important;
        border: 3px solid @darkgrey;


        &:hover {
            .btn-color(transparent, @black);
            border: 3px solid @black!important;
            background: transparent;
            color: @black!important;
        }
    }

    &.btn-big {
        padding: 0 75px;
        height: 60px;
        line-height: 58px;

        &:before {
            right: 20px!important;
        }
    }

    &.btn-arrow {
        &:before {
            content: "\e931";
            position: absolute;
            font-family: 'icomoon';
            right: 10px;
            .transition(margin ease-in-out .3s);
        }
        &:hover {
            &:before {
                margin-right: -5px;
            }
        }
    }
}


@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?axa538');
  src:  url('../fonts/icomoon.eot?axa538#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?axa538') format('truetype'),
    url('../fonts/icomoon.woff?axa538') format('woff'),
    url('../fonts/icomoon.svg?axa538#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  // font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fax:before {
  content: "\e903";
}
.icon-readmore:before {
  content: "\e900";
}
.icon-guest:before {
  content: "\e901";
}
.icon-size:before {
  content: "\e902";
}
.icon-left:before {
  content: "\e906";
}
.icon-right:before {
  content: "\e907";
}
.icon-down-chevron:before {
  content: "\e904";
}
.icon-lense:before {
  content: "\e908";
}
.icon-check:before {
  content: "\e909";
}
.icon-gallery:before {
  content: "\e90a";
}
.icon-envelope:before {
  content: "\e90b";
}
.icon-phone:before {
  content: "\e90c";
}
.icon-calendar:before {
  content: "\e90d";
}
.icon-language:before {
  content: "\e90e";
}
.icon-plus:before {
  content: "\e90f";
}
.icon-car:before {
  content: "\e905";
}
.icon-facebook:before {
  content: "\e910";
}
.icon-instagram:before {
  content: "\e911";
}
.icon-mail:before {
  content: "\e912";
}