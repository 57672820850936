/*footer*/
footer {
    padding-bottom: 50px;
    position: relative;
    background-color: @black;
    
    @media all and (max-width: 992px) {
        padding-bottom: 100px;
    }

    >.container-full {
        border-bottom: 1px solid @gold;
        margin-bottom: 30px;
    }

    .link-footer {
        padding: 30px 0;
        .flexbox(row);
        justify-content: space-between;
        /*font-family: @serif;*/

        @media all and (max-width: 1280px) {
            width: 100%;
        }

        >p {
            flex-grow: 1;

            a {
                color: @gold;
                letter-spacing: .3em;
                text-transform: uppercase;
                font-size: .75em;
                text-decoration: none;
                .transition(all ease-in-out .3s);

                &:hover {
                    color: lighten(@gold, 15%);
                }
            }
        }
    }

    .policy {
        margin-top: 30px;

        >p >a {
            color: @white;
            font-size: .7em;
        }
    }

    .container {
        align-items: center;

        .logo-footer {
            position: relative;
            top: -25px;

            img {
                margin: 0 auto;
            }
        }

        .dati-hotel {
            color: @white;
            font-size: .8em;
            text-transform: uppercase;
            text-align: center;
			position: relative;
                    @media screen and (max-width: 510px){
                    min-height: 120vw;
                  }
			p:nth-child(1):before {
				content: "";
				/* border: solid 1px red; */
				width: 130px;
				height: 130px;
				position: absolute;
				left: 280px;
				background-image: url(/images/logo-conde-nast.png);
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center center;
				top: 0;
			}
			// p:nth-child(1):after {
			// 	content: "";
			// 	/* border: solid 1px red; */
			// 	width: 130px;
			// 	height: 130px;
			// 	position: absolute;
			// 	right: 280px;
			// 	background-image: url(/images/logo-residenze-epoca.png);
			// 	background-repeat: no-repeat;
			// 	background-size: contain;
			// 	background-position: center center;
			// 	top: 0;
			// }
            p:nth-child(2):before {
                content: "";
                /* border: solid 1px red; */
                width: 130px;
                height: 130px;
                position: absolute;
                left: 120px;
                background-image: url(/images/logo-residenze-epoca.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                top: 0;
            }

            p:nth-child(1):after {
                 content: "";
                 /* border: solid 1px red; */
                 width: 130px;
                 height: 130px;
                 position: absolute;
                 right: 280px;
                 background-image: url(/images/monsignore-punteggio-booking.png);
                 background-repeat: no-repeat;
                 background-size: contain;
                 background-position: center center;
                 top: 0;
                 opacity: 0.6;
            }

                p:nth-child(2):after {
                 content: "";
                 /* border: solid 1px red; */
                 width: 130px;
                 height: 130px;
                 position: absolute;
                 right: 120px;
                 background-image: url(/images/monsignore-tripadvisor-en.png);
                 background-repeat: no-repeat;
                 background-size: contain;
                 background-position: center center;
                 top: 0;
                 opacity: 0.6;

                .IT & {
                    background-image: url(/images/monsignore-tripadvisor-it.png);
                 }

                .EN & {
                    background-image: url(/images/monsignore-tripadvisor-en.png);
                 }

                .FR & {
                    background-image: url(/images/monsignore-tripadvisor-fr.png);
                 }

                .DE & {
                    background-image: url(/images/monsignore-tripadvisor-de.png);
                 }

                .RU & {
                    background-image: url(/images/monsignore-tripadvisor-ru.png);
                 }

                .ES & {
                    background-image: url(/images/monsignore-tripadvisor-es.png);
                 }
            }
			

			@media all and (max-width: 1200px) {
					p:nth-child(1):after {
                        width: 100px;
                        height: 100px;
						right: 120px;
					}
                    p:nth-child(2):after {
                        width: 100px;
                        height: 100px;
                        right: 0px;
                    }
					p:nth-child(1):before{
                        width: 100px;
                        height: 100px;
						left: 120px;
					}
                    p:nth-child(2):before{
                        width: 100px;
                        height: 100px;
                        left: 0px;
                    }
			}
			@media all and (min-width: 601px) and (max-width:990px) {
					// p:nth-child(1):after {
					// 	right: 20px;
					// }
					// p:nth-child(1):before{
					// 	left: 20px;
					// }
                    p:nth-child(1):after {
                        width: 80px;
                        height: 80px;
                        right: 95px;
                    }
                    p:nth-child(2):after {
                        width: 80px;
                        height: 80px;
                        right: 0px;
                    }
                    p:nth-child(1):before{
                        width: 80px;
                        height: 80px;
                        left: 95px;
                    }
                    p:nth-child(2):before{
                        width: 80px;
                        height: 80px;
                        left: 0px;
                    }
			}
			@media all and (max-width: 600px) {
					// p:nth-child(1):before,p:nth-child(1):after {
					// 	display: none;
					// }
                    p:nth-child(1):after {
                        width: 80px;
                        height: 80px;
                        right: 50px;
                    }
                    p:nth-child(2):after {
                        width: 80px;
                        height: 80px;
                        right: 50px;
                        top:100px;
                    }
                    p:nth-child(1):before{
                        width: 80px;
                        height: 80px;
                        left: 50px;
                        top:100px;
                    }
                    p:nth-child(2):before{
                        width: 80px;
                        height: 80px;
                        left: 50px;
                    }
			}
            @media screen and (max-width: 510px)  {

                    //booking
                    p:nth-child(1):after {
                        width: 25vw;
                        height: 25vw;
                        top:60vw;
                    }

                    //tripadvisor
                    p:nth-child(2):after {
                        width: 25vw;
                        height: 25vw;
                        top:90vw;
                    }

                    //johansen
                    p:nth-child(1):before{
                        width: 25vw;
                        height: 25vw;
                        top:60vw;
                    }
                   // residenze d'epoca
                    p:nth-child(2):before{
                        width: 25vw;
                        height: 25vw;
                        top:90vw;
                    }

            }


             @media screen and (max-width: 320px)  {

                    //booking
                    p:nth-child(1):after {
                        width: 23vw;
                        height: 23vw;
                        top:70vw;
                        right:2em;
                    }

                    //tripadvisor
                    p:nth-child(2):after {
                        width: 23vw;
                        height: 23vw;
                        top:100vw;
                        right:2em;
                    }

                    //johansen
                    p:nth-child(1):before{
                        width: 23vw;
                        height: 23vw;
                        top:70vw;
                        left:2em;
                    }
                   // residenze d'epoca
                    p:nth-child(2):before{
                        width: 23vw;
                        height: 23vw;
                        top:100vw;
                        left:2em;
                    }

            }
			
/*
&:before {
				content: "";
				border: solid 1px red;
				width: 130px;
				height: 130px;
				position: absolute;
				left: 0;
				background-image: url(/images/logo-conde-nast.png);
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center center;
			}
*/
            >p >a {
                color: @white;
                font-family: @serif;
            }
/*
            >p:nth-last-child(1),
            >p:nth-last-child(2) {
                display: inline;
            }

            >p:nth-last-child(1) {
                margin-left: 15px;
            }
*/
            a.btn {
                border: 1px solid @gold;
                line-height: 2.4em;
                font-size: 1.2em;

                &:before {
                    color: @gold;
                    vertical-align: middle;
                }
            }
        }
    }

     /*credits*/
        .credits {
            color: @white;
            position: absolute;
            text-align: center;
            width: 100%;
            bottom: 0;

            @media all and (max-width: 992px) {
                /*bottom: 50px;*/
				position: relative;
            }

            p {
                margin: 0;
                padding: 5px 0;
                font-size: .8em;
            }

            a {
                color: @gold;
                outline: none;
            }
        }
}

#dashboard {
    position: fixed;
    z-index: 10;
    left: 0;
    bottom: 0;
    /*height: 50px;*/
    line-height: 50px;
    width: 100%;
    border-top: 1px solid fade(@black, 70%);
    box-shadow: 0 -2px 12px rgba(0,0,0,0.45);
    background-color: darken(@darkgrey, 12%);
	.flexbox !important;
    align-items: center !important;

    .tablet & {
        height: 60px;
        line-height: 60px;
    }

        & > a {
            float: left;
            width: 33.333%;
            font-size: .7em;
            color: #fff;
            text-align: center;
            .border-radius(0);
            height: 100%;
            /*line-height: 1.2;*/
            border-right: 1px solid fade(#fff, 10%);
			white-space: initial;
			padding: 10px 0;

            .tablet & {
                font-size: .8em;
            }

            &:after {
                content: none;
            }

            &:last-child {
                border-right: none;
            }

            &:before {
                font-size: 1.7em;
                display: none;
                margin-right: 0;
            }
        }

	@media (min-width: 768px) {
			display: none !important;
	}



}
