.blocco-full {
	.flexbox;
	background-color: @white;
	margin-top: 50px;
	margin-bottom: 50px;
    height: 25vmax;

    >div {
    	padding: 0;
    }

	.text {
		background-color: @white;
		text-align: center;
		padding: 0;
		width: 100%;
		position: absolute;
		top: 50%;
		.transform(translateY(-50%));

		>h2 {
			text-transform: uppercase;
			font-size: 2.4em;
		}
		>h3 {
			font-style: italic;
			font-size: 1.2em;
			margin-bottom: 50px;
			margin-top: 0px;
		}
	}

	&.testo-destra {
		.flexbox(row-reverse);

		.wrap-text {
			border-left: 80px solid @beige;
		}

	}

}

.lista-attivita {
	li {
		overflow: hidden;
		.flexbox(row-reverse);
		margin: 0;

		&:nth-child(1) {
			width: 39%;
			height: 40vmax;
			float: right;
			margin-left: 1%;
		}

		&:nth-child(2) {
			float: left;
			width: 60%;
			height: 19.5vmax;
			margin-bottom: 1vmax;

			.box {
				left: 25%;
			}
		}
		&:nth-child(3) {
			width: 29.5%;
			height: 19.5vmax;
			float: left;
		}

		&:nth-child(4) {
			height: 19.5vmax;
			width: 29.5%;
			float: left; 
			margin-left: 1%;
		}

		.box {
			bottom: 0;
			left: 50%;
			.transform(translateX(-50%));
			padding: 40px 30px;
			width: 280px;

			h2 {
				text-transform: uppercase;
				margin-top: 0;
			}
		}
	}
}

.list-accomodation {
	margin-top: 50px;
	/*margin-bottom: 50px;*/
	height: 32vmax;
	overflow: hidden;
	border-right: 1px solid fade(@darkgrey, 30%);

	.tab-content {
		padding: 0;

		.tab-pane {
			.text {
				position: absolute;
				bottom: 60px;
				width: 100%;
				text-align: center;

				a.btn {
					background-color: @white;

					&:hover {
						background-color: @darkgrey;						
					}
				}
			}
		}
	}
	.tab-control {
		background-color: @white;
		padding: 0;

		.nav-stacked {
			padding: 0;
			width: 100%;

			li + li {margin-top: 0;}

			li:nth-child(4) {
				display: none;
			}
			
			>li {
				height: 10.7vmax;
				border-bottom: 1px solid fade(@darkgrey, 30%);
				position: relative;
				width: 120%;
				left: 0;
				.transition(all ease-in-out .3s);

				&:before {
					position: absolute;
					content: "";
					background-color: @beige;
					border-top: 1px solid @beige;
					border-bottom: 1px solid @beige;
					width: 30px;
					height: 100%;
					top: 0;
					left: 0;
					.opacity(0);
					.transition(all ease-in-out .3s .15s);
				}

				&.active {
				    left: -20%;

				    &:before {
				    	left: -30px;
				    	.opacity(1);
				    }
				}

				>a {
					line-height: 10.5vmax;
				    height: 100%;
				    width: 100%;
				    padding: 0;
				    color: @darkgrey;
				    background-color: @white;
				    padding: 0 50px;
				    text-align: left;
				    font-size: 2.4em;
				    position: relative;
				    z-index: 2;

				    &:hover {
						background-color: @darkgrey!important;
						color: @white;
					}
				}

				&:first-child {
					/*border-top: 1px solid fade(@darkgrey, 30%);*/

					&:before {
						box-sizing: content-box;
						border-bottom: 0;
						top: -1px;
					}
				}
			}
		}
	}
}

.list-accomodation-4 {
	margin-top: 50px;
	/*margin-bottom: 50px;*/
	height: 32vmax;
	overflow: hidden;
	border-right: 1px solid fade(@darkgrey, 30%);

	.tab-content {
		padding: 0;

		.tab-pane {
			.text {
				position: absolute;
				bottom: 60px;
				width: 100%;
				text-align: center;

				a.btn {
					background-color: @white;

					&:hover {
						background-color: @darkgrey;						
					}
				}
			}
		}
	}
	.tab-control {
		background-color: @white;
		padding: 0;

		.nav-stacked {
			padding: 0;
			width: 100%;

			li + li {margin-top: 0;}

			// li:nth-child(4) {
			// 	display: none;
			// }
			
			>li {
				height: 8vmax;
				border-bottom: 1px solid fade(@darkgrey, 30%);
				position: relative;
				width: 120%;
				left: 0;
				.transition(all ease-in-out .3s);

				&:before {
					position: absolute;
					content: "";
					background-color: @beige;
					border-top: 1px solid @beige;
					border-bottom: 1px solid @beige;
					width: 30px;
					height: 100%;
					top: 0;
					left: 0;
					.opacity(0);
					.transition(all ease-in-out .3s .15s);
				}

				&.active {
				    left: -20%;

				    &:before {
				    	left: -30px;
				    	.opacity(1);
				    }
				}

				>a {
					line-height: 8vmax;
				    height: 100%;
				    width: 100%;
				    padding: 0;
				    color: @darkgrey;
				    background-color: @white;
				    padding: 0 50px;
				    text-align: left;
				    font-size: 2.4em;
				    position: relative;
				    z-index: 2;

				    &:hover {
						background-color: @darkgrey!important;
						color: @white;
					}
				}

				&:first-child {
					/*border-top: 1px solid fade(@darkgrey, 30%);*/

					&:before {
						box-sizing: content-box;
						border-bottom: 0;
						top: -1px;
					}
				}
			}
		}
	}
}

/*recensione*/
.review {
    margin: 0px auto;

    .item {
        display: block;
        width: 70%;
        position: relative;
        /*.opacity(0);*/
        padding-top: 30px;
        padding-bottom: 30px;
        .transition(all ease-in-out .3s .15s);

        blockquote {
            border: none;
            color: @white;
            padding-top: 50px;
            padding-bottom: 50px;

            h2 {
                font-family: @serif;
                font-size: 2.8em;
                margin-bottom: 30px;
                padding-bottom: 30px;
                text-align: left !important;
            }

            p {
                text-align: right;
            }
        }
    }
}

.list-offers.offerte-dettaglio .container-full  .swiper-wrapper {
    flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
		}

/*offerte home*/
.list-offers {
    margin-top: 50px;
    margin-bottom: 50px;
    z-index: 0;
    position: relative;

    >h2,
    >h3 {
    	text-align: center;
    }
    >h2 {
    	color: @gold;
    	font-size: 3em;
    }
    >h3 {
    	font-style: italic;
    	position: relative;
    	color: @darkgrey;
    	margin-bottom: 30px;
    	margin-top: 0px;
    	padding-bottom: 30px;
    	display: inline-block;
    	left: 50%;
    	.transform(translateX(-50%));

    	&:before {
    		content: "";
    		position: absolute;
    		bottom: 0;
    		height: 1px;
    		width: 100%;
    		background-color: @darkgrey;
    	}
    }

    &.offerte-home {
        .container-full {
            padding: 0 50px;
        }

        position: relative;
        /*.opacity(0);*/
        .transition(all ease-in-out .3s);
        /*top: 30px;*/

        /*&.qnt {
            .opacity(1);
            top: 0;
        }*/
    }

    &.offerte-pagina {
        .container-full .swiper-wrapper .swiper-slide {
            position: relative;
            /*.opacity(0);*/
            top: 30px;
            .transition(all ease-in-out .3s);

            /*&.qnt {
                top: 0;
                .opacity(1);
            }*/
        }
    }

    /*>h2 {
        z-index: 1;
        position: relative;
        font-family: @serif;
        font-weight: 900;
        text-align: center;
        font-size: 3.6em;
        padding: 40px;
    }*/

    .container-full {
        .swiper-wrapper {
            .flexbox(row);
            align-content: space-between;

            .swiper-slide {
                width: 31%;
                height: 22vmax;
                color: @black;
                text-align: right;
                position: relative;
                font-size: .9em;
                cursor: pointer;
                .transition(all ease-in-out .3s);
                .box-shadow(inset 0 0 0 1px fade(@gold, 0%));
                background-color: @white;

                >.background-cover {
            	    height: 12vmax;
                	
                	>span {
                		.opacity(1);
                	}
                }                

                .text {
                	position: relative;
                	color: @darkgrey;
                	text-align: center;
                	padding: 0 20px;

                	h2 {
	                    font-family: @serif;
	                }

	                >a {
	                	margin: 20px auto 0;
	                    text-decoration: none;
	                    display: block;
	                    width: 40%;
	                    .transition(all ease-in-out .3s .1s);

	                    &:before {
	                    }

	                    &:hover {
	                    }
	                }
                }
            }
        }
		
		

        .swiper-button {
        width: 100%;

	        .swiper-button-next,
	        .swiper-button-prev {
	            color: @gold;
	            z-index: 5;
	            font-family: "icomoon";
	            position: absolute;
	            top: 50%;
	            width: 50px;
	            height: 50px;
	            // background-color: fade(@black, 50%);

	            .mobile & {
	                width: 30px;
	                height: 30px;
	            }
	        }

	        .swiper-button-next {
	            right: 0;

	            &:before {
	                content: "\e907";
	                line-height: 50px;
	                font-size: 25px;
	                position: absolute;
	                left: 50%;
	                margin-left: -12px;

	                .mobile & {
	                    line-height: 30px;
	                }
	            }
	        }
	        .swiper-button-prev {
	            left: 0;

	            &:before {
	                content: "\e906";
	                line-height: 50px;
	                font-size: 25px;
	                position: absolute;
	                right: 50%;
	                margin-right: -12px;

	                .mobile & {
	                    line-height: 30px;
	                }
	            }
	        }
    	}
    }
}/*blog home*/
.list-blog {
    margin-top: 50px;
    margin-bottom: 50px;
    z-index: 0;
    position: relative;

    >h2,
    >h3 {
    	text-align: center;
    }
    >h2 {
    	color: @gold;
    	font-size: 3em;
    	margin-top: 0;
    }
    >h3 {
    	font-style: italic;
    	position: relative;
    	color: @darkgrey;
    	margin-bottom: 30px;
    	margin-top: 0px;
    	padding-bottom: 30px;
    	display: inline-block;
    	left: 50%;
    	.transform(translateX(-50%));

    	&:before {
    		content: "";
    		position: absolute;
    		bottom: 0;
    		height: 1px;
    		width: 100%;
    		background-color: @darkgrey;
    	}
    }

    &.blog-home {
        .container-full {
            padding: 0 50px;
        }

        position: relative;
        /*.opacity(0);*/
        .transition(all ease-in-out .3s);

        /*&.qnt {
            .opacity(1);
            top: 0;
        }*/
    }

    &.blog-pagina {
        .container-full .swiper-wrapper .swiper-slide {
            position: relative;
            /*.opacity(0);*/
            top: 30px;
            .transition(all ease-in-out .3s);

            /*&.qnt {
                top: 0;
                .opacity(1);
            }*/
        }
    }

    /*>h2 {
        z-index: 1;
        position: relative;
        font-family: @serif;
        font-weight: 900;
        text-align: center;
        font-size: 3.6em;
        padding: 40px;
    }*/

    .container-full {
        .swiper-wrapper {
            .flexbox(row);
            align-content: space-between;

            .swiper-slide {
                width: 31%;
                height: 22vmax;
                color: @black;
                text-align: right;
                position: relative;
                font-size: .9em;
                cursor: pointer;
                .transition(all ease-in-out .3s);
                .box-shadow(inset 0 0 0 1px fade(@gold, 0%));
                background-color: @black;

                &.background-cover {
            	    height: 20vmax;
                	
                	>span {
                		.opacity(.6);
                	}
                }                

                .text {
                	position: absolute;
                	top: 10%;
                	left: 50%;
                	.transform(translateX(-50%));
                	width: 80%;
                	color: @white;
                	text-align: center;
                	

                	h2 {
	                    font-family: @serif;
	                    padding-bottom: 30px;
    					margin-bottom: 30px;
    					margin-top: 0px;
    					position: relative;

    					&:after {
    						content: "";
    						position: absolute;
    						bottom: 0;
    						left: 20%;
    						background-color: @white;
    						height: 1px;
    						width: 60%;
    					}
	                }
                }

                >a {
	                	position: absolute;
	                	bottom: 40px;
	                	left: 50%;
	                	.transform(translateX(-50%));
	                    .transition(all ease-in-out .3s .1s);
	                    background-color: fade(@black, 50%);
	                    border: 1px solid @white;

	                    &:before {
	                    }

	                    &:hover {
	                    	background-color: fade(@black, 100%)!important;
	                    	border: 1px solid @white!important;
	                    	color: @white!important;
	                    }
	                }
            }
        }

        .swiper-button {
        width: 100%;

	        .swiper-button-next,
	        .swiper-button-prev {
	            color: @gold;
	            z-index: 5;
	            font-family: "icomoon";
	            position: absolute;
	            top: 50%;
	            width: 50px;
	            height: 50px;
                margin-top: -25px;
	            // background-color: fade(@black, 50%);

	            .mobile & {
	                width: 30px;
	                height: 30px;
	            }
	        }

	        .swiper-button-next {
	            right: 0;

	            &:before {
	                content: "\e907";
	                line-height: 50px;
	                font-size: 25px;
	                position: absolute;
	                left: 50%;
	                margin-left: -12px;

	                .mobile & {
	                    line-height: 30px;
	                }
	            }
	        }
	        .swiper-button-prev {
	            left: 0;

	            &:before {
	                content: "\e906";
	                line-height: 50px;
	                font-size: 25px;
	                position: absolute;
	                right: 50%;
	                margin-right: -12px;

	                .mobile & {
	                    line-height: 30px;
	                }
	            }
	        }
    	}
    }
}
/*come raggiungerci*/
.how-reach-us {
	margin: 0px auto;
	padding-top: 50px;
	padding-bottom: 50px;
	position: relative;
	overflow: hidden;

		&:before {
			color: fade(@grey, 100%);
			font-size: 20vmax;
			position: absolute;
			top: -30px;
			left: 10%;
		}

	.container-full {

		>h3 {
			color: @gold;
			text-align: center;
			font-size: 2.8em;
    		margin-top: 0px;
    		margin-bottom: 30px;

    		&:before {
				vertical-align: middle;
				bottom: 5px;
				position: relative;
				margin-right: 15px; 
    		}
		}

		>.item {
			padding: 30px 3% 0;
		} 
	}
}

.cta {
	>p {
		margin-bottom: 0;
		height: 8vw;
		height: 8vmax;
		/*border-top: 1px solid fade(@white, 30%);*/
		/*border-bottom: 1px solid fade(@white, 30%);*/
		background-color: @darkgrey;
		position: relative;
		width: 50%;
		float: left;
		.transition(all ease-in-out .3s);

		&:first-child {
			border-right: 1px solid fade(@white, 30%);

			&:after {
				content: "";
				position: absolute;
				width: 0;
				height: 10px;
				bottom: 0;
				right: 0;
				background-color: @darkgrey;
			    .transition(all ease-in-out .3s);
			}

			&:before {
				font-family: "icomoon";
				content: "\e906";
				position: absolute;
				top: 0;
				left: 10%;
				color: @white;
				line-height: 8vw;
				line-height: 8vmax;
			    font-size: 3vw;
			    font-size: 3vmax;
			    .transition(all ease-in-out .5s);
			}
		}

		&:last-child {
			&:after {
				content: "";
				position: absolute;
				width: 0;
				height: 10px;
				bottom: 0;
				left: 0;
				background-color: @darkgrey;
			    .transition(all ease-in-out .3s);
			}

			&:before {
				font-family: "icomoon";
				content: "\e907";
				position: absolute;
				top: 0;
				right: 10%;
				color: @white;
				line-height: 8vw;
				line-height: 8vmax;
			    font-size: 3vw;
			    font-size: 3vmax;
			    .transition(all ease-in-out .5s);
			}

		}

		&:first-child {
			&:hover {
				&:after {
					width: 100%;
				}
				&:before {
					left: 5%;
				}
			}
		}
		&:last-child {
			&:hover {
				&:after {
					width: 100%;
				}
				&:before {
					right: 5%;
				}
			}
		}

		&:hover {
			background-color: @gold;

			a {
				letter-spacing: .05em;
			}
		}

		a {
			.btn;
		    line-height: 8vw;
		    line-height: 8vmax;
		    height: 100%;
		    width: 100%;
		    padding: 0;
		    padding: 0 50px;
		    text-align: left;
		    font-size: 1.6em;
		    position: relative;
		    z-index: 2;
			text-align: center;
			color: @white;
		}
	}
}
.serviziCamera .room-services .background-cover {
	height: 40vw;
}
.serviziCamera .room-services {
	flex-direction: row !important;
}

.matrimoni .room-services .background-cover, .ville .room-services .background-cover {
	height: 40vw;
}
.matrimoni .room-services, .ville .room-services  {
	flex-direction: row !important;
}


.room-services {
	padding: 0;
	margin: 0;
	margin-top: 50px;
	margin-bottom: 50px;
	height: auto;
	flex-direction: row-reverse;

	.background-cover {
		.storia & {
			height: 30vw;
		}
	}

	.bg-white {
		z-index: 1;
		padding: 40px;
		/*margin-left: 30px;*/
		text-align: left;

		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: -80px;
			width: 80px;
			height: 100%;
			background-color: @beige;
		}

		>h2,
		>h3 {
			text-align: center;
			margin-top: 0;
		}

		>h2 {
			text-transform: uppercase;
			font-size: 2.8em;
		}
		>h3 {
			font-size: 1.2em;
			position: relative;
			padding-bottom: 19px; 
			margin-bottom: 20px; 

			&:after {
				content: "";
				position: absolute;
				left: 15%;
				bottom: 0;
				height: 1px;
				width: 70%;
				background-color: @black;
			}
		}
	}

	&-detail {
		flex-direction: row;

		.bg-white {
			@media all and (min-width: 1201px) {
                margin-left: -4.166666666666667%;
            }
		}
	}
}

.list-rooms {
	margin-top: 50px;
	margin-bottom: 50px;
	background-color: @white;
	padding-top: 10px;
	ul {
		li {
			margin: 0!important; 
			height: 25vw;
			border-bottom: 10px solid @white;

			&:nth-child(3n+2) {
				border-left: 10px solid @white; 
				border-right: 10px solid @white; 
			}

			.box {
				bottom: 0;
				width: 70%;
				height: 15vw;
				left: 15%;
				background-color: fade(@white, 75%);

				h2 {
					text-transform: uppercase;
					text-align: center;
					margin-top: 30px;
				    padding: 0 5%;
				    font-size: 2em;
				}

				.wrap {
					position: absolute;
					bottom: 0;
					text-align: center;
					padding: 20px;	
					left: 50%;
					width: 80%;
					.transform(translateX(-50%));				

					.details {
						font-style: italic;
						border-bottom: 1px solid @gold;
						padding-bottom: 15px;
						margin-bottom: 25px;
					}
				}
			}
		}
	}
}




.gallery-page {
    // margin-top: 105px;
    
    >h3 {
    	font-size: 2.2em;
    	color: @gold;
    	margin-bottom: 30px;
    	text-align: center;
	    margin-top: 70px;
    }

    &:first-child {
    	>h3 {
    		margin-top: 0;
    	}
    }


    @media all and (max-width: 769px) {
        margin-top: -40px;

        .tablet & {
            margin-top: 70px;
        }
    }

    .background-cover {
        padding: 0;
        background-color: @darkgrey;
        overflow: hidden;
        position: relative;
        outline: none;

         >span {
            .transition(all ease-in-out 1s);
            position: relative;

            &:after {
                font-family: "icomoon";
                content: "\e90a";
                font-size: 5em;
                color: fade(@white, 70%);
                background: transparent;
                .opacity(0);
                position: absolute;
                z-index: 1;
                left: 50%;
                bottom: -50%;
                cursor: pointer;
                .transform(translate(-50%, 50%));
                .transition(all ease-in-out .3s);
                // text-shadow: 1px 1px 1px fade(@black, 50%), 0 0 40px fade(@black, 50%);

                .mobile &,
                .tablet & {
                    bottom: 50%;
                    .opacity(0);
                }
            }
        }


        &:hover {
             >span {
                // .transform-multiple(scale(1.1), rotate(-1deg));

                &:after {
                    bottom: 50%;
                    .opacity(1);
                }
            }
        }
/*

        @media all and (min-width: 1200px) {
            &:nth-child(6n) {
                .image-cover {
                    border-right: none;
                }
            }
        }

        @media all and (max-width: 1199px) and (min-width: 992px) {
            &:nth-child(3n) {
                .image-cover {
                    border-right: none;
                }
            }
        }
        @media all and (max-width: 991px) and (min-width: 769px) {
            &:nth-child(2n) {
                .image-cover {
                    border-right: none;
                }
            }
        }
        @media all and (max-width: 768px) {
            .image-cover {
                border-right: none;
            }
        }*/

        .image-cover {
            position: relative;
            z-index: 1;
            left: 0;
            top: 0;
            height: 15vw;
            float: left;
            background-size: cover;
            background-position: center center;
            border-right: 2px solid #fff;
            border-bottom: 2px solid #fff;
            overflow: hidden;

            .mobile & {
                height: 45vmax;
            }

            &:before {
                background-color: #fff;
            }


            /*@media all and (max-width: 769px) {
                width: 100vw;
                height: 100vw;
                border-right: none;

                .tablet & {
                    width: 50vw;
                    height: 50vw;
                }

                .mobile & {
                    height: 45vmax;
                }

                &:first-child {
                    .tablet & {
                        border-right: 2px solid #fff;
                    }
                }
            }
            @media all and (min-width: 770px) and (max-width: 1024px) {
                height: 35vw;
            }
            @media all and (min-width: 1025px) and (max-width: 1200px) {
                height: 20vw;
            }*/

            // &:hover {
            //     .opacity(.9);
            // }
        }

        @media all and (orientation: landscape) {
            &.col-xs-12 {
                .mobile & {
                    width: 50%;
                    border-right: 2px solid @white;

                    .image-cover {
                        width: 50vw;
                    }
                }
            }

            &:nth-child(2n) {
                .mobile & {
                    .image-cover {
                        border-right: none;
                    }
                }
            }
        }
    }
}



.video {
	>div {
		margin-bottom: 50px;

		h4 {
			color: #98844f; 
			text-transform: uppercase; 
			margin-bottom: 20px; 
			font-size: 0.9em;
		}

		.background-cover {
			 >span {
			 	height: 22.5vw!important;
			    .transition(all ease-in-out 1s);
			    position: relative;

			    &:before {
			    	content: "";
			    	background-color: transparent!important;
			    	background-image: url('../../../images/video/play-button.png');
			    	background-repeat: no-repeat;
  					background-position: center;
			    	.opacity(.6);
			    	width: 128px;
			    	height: 128px;
			        position: absolute;
			        z-index: 1;
			        left: 50%;
			        bottom: 50%;
			        cursor: pointer;
			        .transform(translate(-50%, 50%));
			        .transition(all ease-in-out .3s);

			        .mobile &,
			        .tablet & {
			            bottom: 50%;
			            .opacity(0);
			        }
			    }

			    &:after {
			        content: none;
			    }
			}

			&:hover {
				>span {
					&:before {
						.opacity(.8);
					}
				}
			}
		}
	}
}

// schede ristorante

.list-risto {
	margin-top: 0px;
	margin-bottom: 50px;
	height:30vmax;
	overflow: hidden;
	// border-right: 1px solid fade(@darkgrey, 30%);

	.tab-content {
		padding: 0;

		.tab-pane {
			.text {
					position: relative;
				    top: 25%;
				    width: 40%;
				    text-align: center;
				    background-color: fade(@white, 85%);
				    margin: 0 auto;
				    vertical-align: middle;
				    padding: 30px;

				a.btn {
					background-color: @white;

					&:hover {
						background-color: @darkgrey;						
					}
				}
			}
		}
	}
	.tab-control {
		background-color: @white;
		padding: 0;

		.nav-stacked {
			padding: 0;
			width: 100%;

			li + li {margin-top: 0;}
/*
			>li {
				height: 10vmax;
				// border-bottom: 1px solid fade(@darkgrey, 30%);
				background-color: @white;
				position: relative;
				width: 120%;
				left: 0;
				.transition(all ease-in-out .3s);

				&:before {
					position: absolute;
					content: "";
					background-color: @beige;
					// border-top: 1px solid @beige;
					border-bottom: 1px solid @beige;
					width: 30px;
					height: 100%;
					top: 0;
					left: 0;
					.opacity(0);
					.transition(all ease-in-out .3s .15s);
				}

				&.active {
				    left: -20%;

				    &:before {
				    	left: -30px;
				    	.opacity(1);
				    }
				}

				>a {
					line-height: 1.7vmax;
				    height: 100%;
				    width: 100%;
				    padding: 0;
				    color: @darkgrey;
				    padding: 30px;
				    text-align: left;
				    font-size: 2em;
				    position: relative;
				    z-index: 2;

				    	span{
				    			&:after {
    						content: "";
    						position: absolute;
    						bottom: 30%;
    						left: 5%;
    						background-color: @gold;
    						height: 1px;
    						width: 73%;
    							}

				    	}

				}

				&:first-child {
					// border-top: 1px solid fade(@darkgrey, 30%);

					&:before {
						box-sizing: content-box;
						border-bottom: 0;
						top: -1px;
					}
				}
			}*/


			>li {
				height: 10vmax;
				border-bottom: 1px solid fade(@darkgrey, 30%);
				position: relative;
				width: 120%;
				left: 0;
				.transition(all ease-in-out .3s);

				&:before {
					position: absolute;
					content: "";
					background-color: @beige;
					border-top: 1px solid @beige;
					border-bottom: 1px solid @beige;
					width: 30px;
					height: 100%;
					top: 0;
					left: 0;
					.opacity(0);
					.transition(all ease-in-out .3s .15s);
				}

				&.active {
				    left: -20%;

				    &:before {
				    	left: -30px;
				    	.opacity(1);
				    }
				}

				>a {
					line-height: 0;
				    height: 100%;
				    width: 100%;
				    padding: 0 30px;
				    color: @darkgrey;
				    background-color: @white;
				    padding: 0 50px;
				    text-align: left;
				    font-size: 2.4em;
				    position: relative;
				    z-index: 2;

				    span {
				    	line-height: 10vmax;
				    }

				    &:hover {
						background-color: @darkgrey!important;
						color: @white;
					}
				}

				&:first-child {
					/*border-top: 1px solid fade(@darkgrey, 30%);*/

					&:before {
						box-sizing: content-box;
						border-bottom: 0;
						top: -1px;
					}
				}
			}
		}
	}
}

.list-article {
	margin-bottom: 50px;
	margin-top: 50px;

    &.servizi {
    	// margin-bottom: 50px;

        .k2ItemsBlock .container-full >ul >li .text .wrap_text {
            // font-size: .8em;
        }
        .k2ItemsBlock .container-full >ul >li {
        	/*altri servizi senza immagine*/
        	&:last-child {
        		background-color: @darkgrey;

        		>span {
                	.box-shadow(none);
                    background-color: fade(@white, 0%);
                }

                .text {
                	color: @white;
                	top: 50%;
					.transform(translate(-50%, -50%));

					h2:before {
						width: 0%;
					}

                	.wrap_text {
                    	background-color: transparent;
                    	.opacity(1);
                    	padding: 0;
                    	border-left: none;
                    }
                }
        	}
        }
    }

    &.attivita {
    	margin-bottom: 50px;
		.k2ItemsBlock .container-full >ul >li {

			.mobile &,
			.tablet &,
			&:hover {
				.text {
					top: 25%!important;
				}
				
				>a {
					bottom: 180px;
				}
			}

			&:last-child {
        		background-color: @darkgrey;

        		>span {
                	.box-shadow(none);
                    background-color: fade(@white, 0%);
                }

                .text {
                	color: @white;
                	top: 10%;
					.transform(translate(-50%, -50%));

					h2:before {
					width: 0%;
					}

                	.wrap_text {
                    	background-color: transparent;
                    	.opacity(1);
                    }
                }

                >a {
                	.opacity(1);
                	bottom: 180px;
                }
        	}
		}
    }

    &.altre-attivita,
    &.servizi-family {
    	.k2ItemsBlock .container-full >ul >li {
			&:last-child {
			    width: 100%;
			    height: 15vw;
			}
    	}
    }

    .k2ItemsBlock {
        .container-full {
            >ul {
                padding: 0;

                >li {
                	background-color: @black;
                    overflow: hidden;
                    width: 48.5%;
                    height: 27.5vw;
                    float: left;
                    margin-bottom: 3%;

                    &:nth-last-child(1),
                    &:nth-last-child(2) {
                    	margin-bottom: 0;
                    }

                    /*top: 30px;*/
                    
                    /*&:nth-child(odd) {
                        .transition(all ease-in-out .3s .2s);
                    }
                    &:nth-child(even) {
                        .transition(all ease-in-out .3s .4s);
                    }*/

                    @media all and (max-width: 1281px) {
                        height: 35vw;
                    }

                    >span {
                    	.box-shadow(inset 0 12vmax 20vmax -10vmax @black);
                        .transition(all ease-in-out .3s);
                        filter: blur(0.3px);
                        -webkit-filter: blur(0.3px);
                        -moz-filter: blur(0.3px);

						-webkit-transform: translateZ(0);
						-webkit-backface-visibility: hidden;
						-webkit-perspective: 1000;
                    }

                    &:nth-child(2n+1) {
                        margin-right: 3%;
                    }

                    .text {
                        color: @darkgrey;
                        width: 75%;
                        left: 50%;
                        top: 5%;
                        .transform(translate(-50%, 0%));
                        .transition(all ease-in-out .4s .1s);
                        font-size: 1vw;
                        line-height: 1.3vw;

                        a {
                            color: @gold;
                        }

                        >h2 {
                            font-size: 2vw;
                            font-family: @serif;
                            position: relative;
                            margin-top: 0;
                            color: @white;
/*
                            &:before {
                                content: "";
                                position: absolute;
                                left: 0;
                                bottom: 0;
                                height: 1px;
                                width: 100%;
                                background-color: @white;
                                .transition(all ease-in-out .2s);
                            }*/
                        }

                        >h3 {
                            font-size: 1.8vw;
                            font-family: @serif;
                        }

                        .wrap_text {
                        	background-color: fade(@white, 100%);
                            padding: 15px 30px;
                            .opacity(0);
                            .transition(all ease-in-out .4s .1s);
                            border-left: 80px solid @beige;


                            h3 {
                        	    text-transform: uppercase;
								font-weight: bold;
                            }

                            p {
                                // line-height: 0;
                                .transition(all ease-in-out .4s .1s);

                            }

                            ul {
                            	list-style-type: circle;
							    margin: 0;
							    padding: 0;

                            	li {
                        		    display: list-item;
                            		position: relative;
                            		margin-bottom: 15px;
                        		    line-height: 1em;

                            		&:last-child {
                            			margin-bottom: 0;
                            		}

                            		/*&:before {
                            			content: "\e907";
                            			font-family: "icomoon";
                        			    font-weight: bold;
    									font-size: .5em;
                            			left: 0;
                            			top: 0;
                            		}*/
                            	}
                            }

	                        /*&:before {
	                            content: "";
	                            position: absolute;
	                            top: 0;
	                            left: -80px;
	                            width: 80px;
	                            height: 100%;
	                            background-color: @beige;
	                        }*/
                        }
                    }

                    >a {
                        	.opacity(0);
                            .transition(all ease-in-out .4s .1s);
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            .transform(translateX(-50%));
                        }
                    
                    .mobile &,
                    .tablet &,
                    &:hover {
                        >span {
                        	.box-shadow(inset 0 20vmax 20vmax -14vmax @black);
                            background-color: fade(@white, 75%);
                            .transform(scale(1.05));
                            .opacity(.5);
                            filter: blur(2px);
                            -webkit-filter: blur(2px);
                        	-moz-filter: blur(2px);
                        }

                        .text {
                            top: 50%;
                            .transform(translate(-50%, -50%));

                            h2:before {
                                width: 0%;
                            }

                            .wrap_text {
                                .opacity(1);

                                p {
                                    // line-height: 1em;
                                }
                            }
                        }

                        >a {
                        	.opacity(1);
                        	bottom: 100px;
                        }
                    }
                }
            }
        }
    }
}

/*mappa */
.googlemap {
    margin: 0;
    margin-top:60px;
    // .opacity(0);
    .transition(all ease-in-out .3s .1s);

    >p {
    	margin: 0!important;
    }

    &.qnt {
        .opacity(1);
    }

    .phocamaps {
        background-color: white;
        width: 100%;
        margin-bottom: 0px !important;
        background-color: @white;

        #phocamaps-box {
            margin-top: 0!important;
            float: left;
            margin: 0;
            width: 70%!important;

            @media all and (max-width: 1201px) {
                width: 100%!important;
            }
        }

        .pmroute {
            margin-left: 0;
            margin-top: 0;
            padding: 20px 30px;
            color: @gold;
            width: 30%;
            float: left;
            clear: none;
            height: 100%;
            display: table;
            text-align: center;

            @media all and (max-width: 1201px) {
                width: 100%;
                display: block;
            }

            > .form-inline {
                padding-top: 18%;
                display: table-cell;
                vertical-align: middle;
                font-weight: 500;
                font-size: 2.6em;
                font-family: @serif;
                color: @gold;

                @media all and (max-width: 1201px) {
                    font-size: 1.6em;
                    display: block;
                    padding-top: 0;
                }
            }

            form {
                .pm-input-route {
                    background-color:fade(@white, 5%);
                    border: none;
                    border-bottom: 2px solid @gold;
                    height: 40px;
                    vertical-align: top;
                    color: @black;
                    padding: 5px;
                    display: block;
                    margin: 10px 10%;
                    width: 80%;
                    border-radius: 0px;
                    font-size: .7em;
                    outline: none;

                    @media all and (max-width: 1023px) {
                        width: 90%;
                        margin: 10px 5%;
                    }

                    @media all and (max-width: 769px) {
                        margin: 10px auto;
                    }

                    .mobile & {
                        width: 100%;
                    }
                }

                .pm-input-route-btn {
                    color: @gold;
                    border: 3px solid #ffffff;
                    background: @white;
                    width: 80%;
                    font-size: .8em;
                    border:3px solid @gold;
                    font-size: 0.32em;
                    line-height: 1.5;
                    font-family: @serif;
                    height: 60px;
                    outline: none;

                    @media all and (max-width: 1200px) {
                        font-size: .5em;
                    }
                    @media all and (max-width: 1023px) {
                        width: 90%;
                    }

                        &:hover {
                            color: @white;
                            background: @gold;
                            border:3px solid @gold;
                        }

                    .mobile & {
                        width: 100%;
                    }
                }
            }
        }

        #phocaDirPlgPM1 {
            /*background-color: lighten(@gold, 30%);*/
            border-top: darken(@gold, 30%);

            #phocaMapsPrintIconPlgPM1 {
                background-color: @white;

                .pmprintroutelink {
                    display: none;
                }
            }

            div {
                background-color: @white;
                div {
                    width: 80%;
                    margin: 0 10%;

                    .mobile &,
                    .tablet & {
                        @media all and (max-width: 769px) {
                            width: 100%;
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
}
#phocamaps-box div.pmbox-grey div div div {
    padding: 0 !important;
}

/*-----mappa dove siamo-------------------------------------------------------*/
.mappainterattiva {
    padding: 0;
    height: 760px;
    float: none;
    position: relative;
    z-index: 0;
    margin-bottom: 50px;
    margin-top: 50px;
    // .opacity(0);
    .transition(all ease-in-out .3s .1s);

    &.qnt {
        .opacity(1);
    }

    @media all and (max-width: 769px) {
        height: auto;
        .flexbox(column);
    }

    @media all and (max-width: 769px) {
        .tablet & {
            display: block;
            height: auto;
        }
    }

    #interactive-map {
        padding: 0;
        height: 100%;

        @media all and (max-width: 769px) {
            .tablet & {
                display: block;
                height: 450px;
            }
        }

        #map {
            // min-height: 500px;
            // height: 100%;
        }
        @media all and (max-width: 769px) {
            display: block;
            height: 350px;
            min-height: none;
        }
    }

    #interactive-map #map {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%
    }

@media all and (max-width: 767px) {
    #interactive-map #map {
        left:0;
        position: relative;
        // height: 250px;
        top: 0;
        width: 100%
    }
}

#interactive-map #map .marker {
    text-align: center;
    transition: all .3s linear;
    -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear
}

#interactive-map #map .marker:before {
    position: relative;
    z-index: 2;
    top: 0;
    width: 60px;
    height: 60px;
    display: block;
}

#interactive-map #map .marker#hotel-marker:before {
    content: none;
    background-image: url('/images/marker/hotel-monsignor-marker.png');
    z-index: 3;
    width: 128px;
    height: 128px;
}

#interactive-map #map .marker#Autodromo-marker:before,
#interactive-map #map .marker#Aeroporto-marker:before,
#interactive-map #map .marker#Firenze-marker:before,
#interactive-map #map .marker#outletBarberino-marker:before,
#interactive-map #map .marker#outlettheMall-marker:before,
#interactive-map #map .marker#borgosanlorenzo-marker:before,
#interactive-map #map .marker#scarperia-marker:before,
#interactive-map #map .marker#golf-marker:before,
#interactive-map #map .marker#Bologna-marker:before,
#interactive-map #map .marker#eatalyWorld-marker:before,
#interactive-map #map .marker#Pisa-marker:before,
#interactive-map #map .marker#Siena-marker:before,
#interactive-map #map .marker#Lucca-marker:before,
#interactive-map #map .marker#Maranello-marker:before,
#interactive-map #map .marker#Lamborghini-marker:before,
#interactive-map #map .marker#Riomaggiore-marker:before, 
#interactive-map #map .marker#Arezzo-marker:before, 
#interactive-map #map .marker#AeroportoPisa-marker:before, 
#interactive-map #map .marker#AeroportoBologna-marker:before, 

{
    content: "";
    background-image: url('/images/marker/hotel-monsignor-POI.png');
    position: relative;
    z-index: 2;
    top: 0;
    width: 50px;
    height: 50px;
    display: block;
}

#interactive-map #map .marker.active:before {
    top: 0;
    background-image: url('/images/marker/hotel-monsignor-POI-active.png')!important;
    z-index: 4;
    width: 50px!important;
    height: 50px !important;
}

#interactive-map .marker-poi {
    position: relative;
    width: 20%;
    z-index: 2;
    color: #fff;
    background-color: @gold;
    padding: 0;
    box-shadow: 0 0 24px rgba(74,49,49,0.5);
    -moz-box-shadow: 0 0 24px rgba(74,49,49,0.5);
    -webkit-box-shadow: 0 0 24px rgba(74,49,49,0.5)
}

@media all and (max-width: 1200px) {
    #interactive-map .marker-poi {
        width:40%
    }
}

#interactive-map .marker-poi .custom {
    height: 100%
}

#interactive-map .marker-poi .marker-link {
    margin: 0
}

#interactive-map .marker-poi .marker-link a {
    position: relative;
    z-index: 1;
    font-size: 1rem;
    font-weight: 100;
    color: #fff;
    display: block;
    padding: 15px 30px;
    border-bottom: 1px solid rgba(255,255,255,0.15);
    transition: all .3s linear;
    -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear
}

#interactive-map .marker-poi .marker-link a:before {
    position: absolute;
    right: 15px;
    top: 50%;
    margin-top: -9px
}

#interactive-map .marker-poi .marker-link a strong {
    display: block;
    font-size: .9em;
    text-transform: uppercase
}

#interactive-map .marker-poi .marker-link a:hover,#interactive-map .marker-poi .marker-link a:focus {
    text-decoration: none;
    // background-color: rgba(208,70,68,0.5)
    // background-color: fade(@gold, 80%);
}

#interactive-map .marker-poi .marker-link.active a {
    // background-color: fade(@gold, 80%);
    z-index: 2;
    box-shadow: 0 0 24px rgba(74,49,49,0.5);
    -moz-box-shadow: 0 0 24px rgba(74,49,49,0.5);
    -webkit-box-shadow: 0 0 24px rgba(74,49,49,0.5)
}

#interactive-map .marker-poi .marker-link.active a:before {
    color: #4a3131
}

#interactive-map .marker-poi .marker-link:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    right: -100%;
    top: 0;
    background-position: center center;
    background-size: cover;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    zoom:1;transition: all .6s linear;
    -moz-transition: all .6s linear;
    -webkit-transition: all .6s linear;
    -o-transition: all .6s linear;
    border-right: 5px solid #d04644
}

@media all and (max-width: 1200px) {
    #interactive-map .marker-poi .marker-link:after {
        display:none
    }
}

#interactive-map .marker-poi .marker-link.active:after {
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    // filter: alpha(opacity=100%);
    zoom:1;
    }

    @media all and (max-width: 680px) {
        #interactive-map .marker-poi {
            width:100%
        }

        #interactive-map .marker-poi .marker-link {
            display: none;
            text-align: center
        }

        #interactive-map .marker-poi .marker-link.active {
            display: block
        }

        #interactive-map .marker-poi .marker-link.active a:after {
            position: absolute;
            bottom: -15px;
            left: 50%;
            margin-left: -7.5px;
            content: "";
            width: 0;
            height: 0;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-top: 20px solid #d04644
        }

        #interactive-map .marker-poi .marker-link.active a:before {
            display: none
        }
    }
}
/*poi*/
.poi {
    background-color:@white;
    color: #fff;
    padding: 0;
    /*border:1px solid @gold;*/

    @media all and (max-width: 769px) {
        .tablet & {
            display: block;
            padding: 20px 0 0;
        }
    }

    > span {
        .opacity(.5);
        .transition(all ease-in-out .3s);
    }

    h3 {
       font-weight: 900;
       text-align: center;
       font-size: 3.6em;
       padding: 5px 20px 12px;
       font-family: @serif;
       color: @gold;
       border-bottom: 1px solid @gold;
    }

    > span {
            position: relative;
            z-index: 0;
    }

    .container-poi {
        position: relative;
        z-index: 1;

        h3 {
            text-align: center;
            font-size: 2vw;
            width: 90%;
            margin: 10px auto 0;

            @media all and (max-width: 767px) {
                width: 90%;
                font-size: 1.6em;
            }
        }

        .table {
            margin: 0px auto;
            margin-top: 5px;
            margin-left: 0;
            border-collapse: inherit;

            tr {
                border-bottom: 1px solid fade(#fff, 20%);
                border-left: 7px solid fade(@gold, 0%);

                &.marker-link.active {
                    border-bottom: 1px solid fade(#fff, 10%);
                    // border-left: 7px solid fade(@gold, 25%);
                    background-color: fade(@gold, 100%);

                    td {
                        background-color: fade(@gold, 0%);

                        div a {
                            color: #fff;

                            span {
                            	color: @white;
                            }
                        }
                    }
                }

                &:last-child {
                        border-bottom: none;
                }

                td {
                    border: none;
                    padding: 0px 20px;
                    line-height: 1.1;

                    div {
                        a {
                            text-decoration: none;
                            .flexbox(row);
                            align-items: center;
                            height: 36px;

                            @media all and (max-width: 1100px) {
                                font-size: .9em;
                            }

                            span {
                                color: @gold;
                                display: inline;
                                width: 70%;
                                float: left;
                                font-weight: bold;
								font-size: .9em;

                                &:last-child {
                                    float: right;
                                    width: 30%;
                                    text-align: right;
                                    font-size: .8em;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*gallery country houses*/

.swiper-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
	
	&-country {
    	margin-top: 0px;
        margin-bottom: 50px;

        .mobile & {
            margin-bottom: 0;
        }

        height: 22vw;
        position: relative;
        padding: 0 40px;

        @media all and (max-width: 1360px) {
            padding: 30px 45px;
        }
        @media all and (max-width: 769px) {
            padding: 20px;
            height: 80vmin;
        }

        .swiper-wrapper {
                .swiper-slide {
                	position: relative;
                	background-color: @black;

                	&:before {
                        position: absolute;
                        font-family: "icomoon";
                        content: "\e90a";
                        top: 50%;
                        left: 50%;
                        font-size: 6vw;
                        .transform(translate(-50%, -50%));
                        color: fade(@white, 60%);
                    }

                    a {
                        display: block;

                        span {
                            .box-shadow(inset 0px 0px 12vw 12vw rgba(0,0,0,0.0););
                            .transition(all ease-in-out .3s);
                            /*height: 20vw;*/

                            &:before {
                            	position: absolute;
                            	top: 50%;
                            	left: 50%;
                            	.transform(translate(-50%, -50%));
                            	color: @white;
                            	font-size: 5em;
                            	.opacity(0);
                            	.transition;
                            }
                        }

                        &:hover {
							span {
								.opacity(.75);
							}
                        }
                    }
                }
        }

       .swiper-button {
           width: 100%;
           position: absolute;
           top: 50%;
           left: 0;

           .swiper-button-next,
           .swiper-button-prev {
               color: #fff;
               z-index: 5;
               font-family: "icomoon";
               position: absolute;
               width: 50px;
               height: 50px;
               background-color: @gold;
               .box-shadow(inset 0 0 0 3px @gold);
               margin-top: -25px;
           }

           .swiper-button-next {
               right: 15px!important;

               &:before {
                   content: "\e907";
                   line-height: 50px;
                   margin-left: 18px;
               }
           }
           .swiper-button-prev {
               left: 15px;

               &:before {
                   content: "\e906";
                   line-height: 50px;
                   margin-left: 18px;
               }
           }
       }
    }
}


//schede wellness

.list-spa {
	margin-top: 50px;
	margin-bottom: 50px;
	height:40vmax;
	overflow: hidden;
	// border-right: 1px solid fade(@darkgrey, 30%);

	.tab-content {
		padding: 0;

		.tab-pane {
			.text {
					position: relative;
				    top: 25%;
				    width: 40%;
				    text-align: center;
				    background-color: fade(@white, 85%);
				    margin: 0 auto;
				    vertical-align: middle;
				    padding: 30px;

				a.btn {
					background-color: @white;

					&:hover {
						background-color: @darkgrey;						
					}
				}
			}
		}
	}
	.tab-control {
		background-color: @white;
		padding: 0;

		.nav-stacked {
			padding: 0;
			width: 100%;

			li + li {margin-top: 0;}

			/*>li {
				height: 10vmax;
				// border-bottom: 1px solid fade(@darkgrey, 30%);
				background-color: @white;
				position: relative;
				width: 120%;
				left: 0;
				.transition(all ease-in-out .3s);
				border-bottom: 1px solid fade(@darkgrey, 30%);

			    &:last-child {
			    	border-bottom: none;
			    }

				&:before {
					position: absolute;
					content: "";
					background-color: @beige;
					// border-top: 1px solid @beige;
					border-bottom: 1px solid @beige;
					width: 30px;
					height: 100%;
					top: 0;
					left: 0;
					.opacity(0);
					.transition(all ease-in-out .3s .15s);
				}

				&.active {
				    left: -20%;

				    &:before {
				    	left: -30px;
				    	.opacity(1);
				    }
				}

				>a {
					line-height: 1.7vmax;
				    height: 100%;
				    width: 100%;
				    padding: 0;
				    color: @darkgrey;
				    padding: 30px;
				    text-align: left;
				    font-size: 2em;
				    position: relative;
				    z-index: 2;

					span {
						&:after {
							content: "";
							position: absolute;
							bottom: 30%;
							left: 5%;
							background-color: @gold;
							height: 1px;
							width: 73%;
						}
					}
				}

				&:first-child {

					&:before {
						box-sizing: content-box;
						border-bottom: 0;
						top: -1px;
					}
				}
			}*/

			>li {
				height: 10vmax;
				border-bottom: 1px solid fade(@darkgrey, 30%);
				position: relative;
				width: 120%;
				left: 0;
				.transition(all ease-in-out .3s);

				&:before {
					position: absolute;
					content: "";
					background-color: @beige;
					border-top: 1px solid @beige;
					border-bottom: 1px solid @beige;
					width: 30px;
					height: 100%;
					top: 0;
					left: 0;
					.opacity(0);
					.transition(all ease-in-out .3s .15s);
				}

				&.active {
				    left: -20%;

				    &:before {
				    	left: -30px;
				    	.opacity(1);
				    }
				}

				>a {
					line-height: 0;
				    height: 100%;
				    width: 100%;
				    padding: 0 30px;
				    color: @darkgrey;
				    background-color: @white;
				    padding: 0 50px;
				    text-align: left;
				    font-size: 1.7em;
				    position: relative;
				    z-index: 2;

				    span {
				    	line-height: 10vmax;
				    }

				    &:hover {
						background-color: @darkgrey!important;
						color: @white;
					}
				}

				&:first-child {
					/*border-top: 1px solid fade(@darkgrey, 30%);*/

					&:before {
						box-sizing: content-box;
						border-bottom: 0;
						top: -1px;
					}
				}
			}
		}
	}
}

.list-spa.list-castle.list-piani {
	.tab-content {

		.tab-pane {
			display: block!important;
			opacity: 0!important;

			&.active {
				opacity: 1!important;
			}

			.swiper-container-gallery {
				.swiper-wrapper {
					.swiper-slide {
						background-color: @black;
						position: relative;
						overflow: hidden;

						&:before {
		                    position: absolute;
		                    font-family: "icomoon";
		                    content: "\e90a";
		                    top: 50%;
		                    left: 50%;
		                    font-size: 6vw;
		                    .transform(translate(-50%, -50%));
		                    color: fade(@white, 60%);
		                }

		                a {
                            &:hover {
                                >span {
                                    .opacity(.75);
                                    .transform(scale(1.05));
                                }
                            }

                            >span {
                                .transition(all ease-in-out .3s);
                            }
                        }
					}
				}

				.swiper-button {
                    width: auto;
                    position: absolute;
                    bottom: 20px;
                    left: 20px;

                    .swiper-button-next,
                    .swiper-button-prev {
                        color: #fff;
                        z-index: 5;
                        font-family: "icomoon";
                        position: relative;
                        width: 50px;
                        height: 50px;
                        background-color: transparent;
                        .box-shadow(inset 0 0 0 3px @white);
						text-align: center;
                    }

                    .swiper-button-next {
                        left: 70px!important;

                        &:before {
                            content: "\e907";
                            line-height: 50px;
                        }
                    }
                    .swiper-button-prev {
                        left: 0;
                        margin-top: -50px;

                        &:before {
                            content: "\e906";
                            line-height: 50px;
                        }
                    }
                }
			}
		}
	}
}

.country {
	.room-services {
		flex-direction: row;
		align-items: center;

		@media all and (max-width: 991px) {
			.flexbox(column);
		}

		.background-cover {
			height: 40vw;
		}
		.bg-white {

		}
	}
}

.ristorante {
	.container-full {
		.room-services {
			flex-direction: row;

			@media all and (max-width: 1199px) {
				flex-direction: column;
			}
		}

		.bg-white {
			@media all and (min-width: 1201px) {
			    margin-left: -4.166666666666667%;
			}
		}
	}
}

.social-menu a {
    width: 70px;
    display: block;
    height: 50px;
    top: -10px;
    position: absolute;
}

.btn.icon-chat:before {
content: " ";
    /* border: solid 1px red; */
    width: 20px;
    display: block;
    position: relative;
    height: 20px;
    /* background-color: green; */
    margin-top: 10px;
	margin-right: 10px;
    float: left;
    background-image: url(/templates/default/images/icona-assistenza.png);
    background-size: contain;
	
}

.risorse-footer {
    margin-top: 10px;

	ul {
		text-align: center;

		li.even, 
		li.odd {
			display: inline-block;
			margin-bottom: 0px;
			margin-right: 10px;

			>a.moduleItemTitle {
				color: fade(@white, 70%);
				font-size: .8em;
				.transition;

				&:hover,
				&:focus {
					color: @white;
				}
			}
		}
	}
}

/* Nav-Tab Swiper Gallery - Castello */


.list-spa.list-castle.list-piani {
    height: 40vw;
}

.list-spa.list-castle.list-piani .swiper-container-gallery {
    height: 40vw;
    margin-bottom: 0!important;
}

.list-spa.list-piani .nav-stacked>li {
    height: 10vw  !important;
}


.swiper-container-gallery .swiper-wrapper .swiper-slide a {
    display: block;
}
.swiper-container-gallery .btn.btn-black-border {
	position: absolute;
    bottom: 23px;
    right: 10px;
    background: white;
	height: 45px;
}

.dvDescContainer {
	position: absolute;
    bottom: 23px;
    color: black;
    background: #98844f;
    right: 0;
    right: calc(50% - 160px);
    transform: translateX(-50%);
	width: 160px;
	text-align: center;
	height: 45px;
    line-height: 45px;
}

.dvDescContainer span {
    display: inline-block;
    line-height: normal;
}

.dvDescription {
    position: absolute;
    bottom: 90px;
    background: #ffffff99;
    right: 0;
    padding: 10px;
	opacity: 0;
	display: none;
    right: calc(50% - 370px);
    transform: translateX(-50%);
	width: 370px;

}

.dvTextContainer {
    position: absolute;
    bottom: 0;
    z-index: 9;
    background: #ffffffa1;
    padding: 20px;
    width: 60%;
    margin: 20%;
}


/* Ipad Verticale */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) { 
	.list-spa.list-castle.list-piani {
		height: 50vw;
	}
	.list-spa.list-castle.list-piani .swiper-container-gallery {
		height: 50vw;
		margin-bottom: 0!important;
	}

	.list-spa.list-piani .nav-stacked>li {
		height: 13vw  !important;
	}
}
/* Mobile Verticale*/

@media only screen and (min-device-width : 300px) and (max-device-width : 767px) and (orientation : portrait) { 
	.list-spa.list-castle.list-piani {
		height: auto;
	}
	.list-spa.list-castle.list-piani .swiper-container-gallery {
		height: auto;
	}
	div#dvContMobile1,
	div#dvContMobile2,
	div#dvContMobile3 {
		padding: 0 0 20px;

		.tab-pane {
			height: auto;
			position: relative;

			.swiper-button-prev, .swiper-button-next {
				top: 15%;
			}

			.swiper-slide > a.btn.btn-black-border {
				display: none;
			}

			.background-cover {
				/*position: relative;*/
			}

			.dvDescContainer {
			    position: relative;
			    bottom: auto;
			    background: transparent;
			    right: auto;
			    transform: translateX(0%);
			    width: auto;
			    text-align: center;
			    height: auto;
			    line-height: 1em;

			    >span {
			    	font-size: 1.2em;
			    }
			}

			.dvDescription {
				position: relative;
				top: auto;
				bottom: auto;
				left: auto;
				right: auto;
				width: auto;
				display: block;
				opacity: 1;
				transform: translateX(0%);
			}
		}
	}

	div#dvContMobile4 {
		padding: 20px;
	}
.list-spa.list-castle.list-piani .swiper-button-next, .list-spa.list-castle.list-piani  .swiper-button-prev {
    background-color: #ffffffa6 !important;
    height: 50px;
	&:before {
		position: absolute;
		top: 15px;
	}
}
.list-spa.list-castle.list-piani .swiper-button-next {
	right: 0;
	&:before {
		right: 0;
	}
}
.list-spa.list-castle.list-piani .swiper-button-prev {
	&:before {
		left: 5px;
	}
}
}



