/* --- CHRONOFORM ----------------------------------------------------------- */
.chrono_credits { display: none; }


/* --- CHRONOFORM ----------------------------------------------------------- */
.chronoforms6_credits { display: none; }
.mobile & {
    .g-recaptcha {
        .transform(scale(.77));
    }
}

form .field {
    position: relative;
}

.two.fields .field.required:before {
content: "*";
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    left: 11px;
    color: red;
    top: 1px;
}

.field.richiesta.required:before {
content: "*";
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    left: 4px;
    color: red;
    top: 1px;
}

.field.required:before {
content: "*";
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    left: 4px;
    color: red;
    top: 1px;
}



.form-contatti {
    /*background-color: @beige;*/
    background-position: 0 50%;
    margin: 0px;
    padding: 50px 10%;

    .mobile & {
        margin-bottom: 30px;
    }

    form {
        div {
            input,
            textarea#Richiesta {
                background-color: darken(@grey, 0%)!important;
                outline: none!important;
                border: none!important;
                /*border-bottom: 3px solid fade(@darkgrey, 60%)!important;*/
                border: 2px solid darken(@grey, 15%)!important;
                vertical-align: top!important;
                color: darken(@grey, 30%)!important;
                padding: 5px!important;
                display: block!important;
                border-radius: 0px!important;
                font-size: 1.2em!important;
                padding-left: 10px!important;

                &:focus {
                    /*border-bottom: 3px solid darken(@gold, 5%)!important;*/
                    border: 2px solid darken(@grey, 30%)!important;
                }
            }
            input {
                height: 50px!important;
            }

            label {
                display: none!important;
            }

            &.error {
                input,
                textarea#Richiesta {
                    border-color: #E0B4B4!important;
                }
            }

            .ui.checkbox input.hidden+label {
                display: block!important;
                color: @black;

                a {
                    color: @black;
                    font-weight: bold;
                }
            }

            .ui.checkbox .box:before, .ui.checkbox label:before {
                background-color: @grey;
                .border-radius(0);
                border: 2px solid darken(@grey, 15%)!important;
            }

            .ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after {
                color: @white;
                background-color: darken(@grey, 15%)!important;
            }
        }
        #btnInvia {
            color: @white!important;
            border: 3px solid @gold!important;
            background: @gold!important;
            .border-radius(0);
            margin-top: 20px;
            font-size: 1.2em;
        }
    }
}


.form-contatti-box {
    /*background-color: @beige;*/
    background-position: 0 50%;
    margin: 0px;
    padding: 0px 10px;

    .mobile & {
        margin-bottom: 30px;
    }

    form {
        div {
            input,
            textarea#Richiesta {
                background-color: darken(@white, 0%)!important;
                outline: none!important;
                border: none!important;
                /*border-bottom: 3px solid fade(@darkgrey, 60%)!important;*/
                border: 2px solid darken(@grey, 15%)!important;
                vertical-align: top!important;
                color: darken(@grey, 30%)!important;
                padding: 5px!important;
                display: block!important;
                border-radius: 0px!important;
                font-size: 1.2em!important;
                padding-left: 10px!important;

                &:focus {
                    /*border-bottom: 3px solid darken(@gold, 5%)!important;*/
                    border: 2px solid darken(@grey, 30%)!important;
                }
            }
            input {
                height: 40px!important;
            }

            label {
                display: none!important;
            }

            &.error {
                input,
                textarea#Richiesta {
                    border-color: #E0B4B4!important;
                }
            }

            .ui.checkbox input.hidden+label {
                display: block!important;
                color: #e0e0e0;

                a {
                    color: #e0e0e0;
                    font-weight: bold;
                }
            }

            .ui.checkbox .box:before, .ui.checkbox label:before {
                background-color: @grey;
                .border-radius(0);
                border: 2px solid darken(@grey, 15%)!important;
            }

            .ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after {
                color: @white;
                background-color: darken(@grey, 15%)!important;
            }
        }
        #btnInvia {
            color: @white!important;
            border: 3px solid @gold!important;
            background: @gold!important;
            .border-radius(0);
            margin-top: 20px;
            font-size: 1.2em;
        }
    }
}

@media all and (max-height:1080px) {
	.form-contatti-box form div input {
		height: 35px!important;
	}
	.form-contatti-box form div input, .form-contatti-box form div textarea#Richiesta {
		font-size: 1.1em!important;
	}
	.radio, .checkbox {
		margin-top: 0px;
		margin-bottom: 0px;
	}
	.box-prenotazione .box .tab-control .nav-stacked>li>a {
		padding: 5px 15px;
	}
}
/* --- BANNER COOKIES ------------------------------------------------------- */

#banner-cookies {
    position: fixed;
    bottom: -100%;
    right: 2%;
    left: 2%;
    width: 96%;
    margin-bottom: 30px;
    z-index: 10;
    font-size: .9rem;
    line-height: 1;
    color: #fff;
    background-color: #222;
    .border-radius(0);
    .transition;

    .mobile & {
        margin-bottom: 70px;
    }

    .btn {
        background-color: #555;
    }

    .keyframes(Cookies;{
        from {bottom: -100%;}
        to {bottom: 0;}
    });

    .animation(Cookies 3s forwards 2s ease-out);
}

.affix {
    #banner-cookies {
        .keyframes(CookiesOut;{
            from {bottom: 0;}
            to {bottom: -100%;}
        });

        .animation(CookiesOut 0s forwards 2s ease-out);
    }
}



#loading {
  display: block;
  position: fixed;
  overflow: scroll;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: @black;

  >img {
    position: absolute;
    left: 50%;
    top: 50%;
    .transform(translate(-50%, -50%));
    /*margin-top: -120px;*/

    @media all and (max-width: 374px) {
        width: 280px;
    }
  }

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    position: absolute;
    left: 50%;
    top: 50%;
    .transform(translate(-50%, -50%));
  }
  .lds-ellipsis div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: fade(@darkgrey, 75%);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 26px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 45px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(19px, 0);
    }
  }

}

/*modifica fancybox*/

@media all and (min-width: 769px) {
    .fancybox-arrow {
        width: 65px!important;

        &:after {
            width: 64px!important;
            height: 64px!important;
            background-size: 32px 32px!important;
        }
    }

    .fancybox-show-nav .fancybox-arrow {
        .opacity(1)!important;
    }
}
