/*SIMPLE BOOKING*/


    .sb {
        max-width: 1600px;

        .tablet & {
            background: inherit;
        }
    }


    @media all and (min-width: 769px) {
        height: 88px;
        width: 100%;
        margin-top: -88px;
        left: 0;
        position: absolute;
        z-index: 2;
        /*background-color: fade(@darkgrey, 80%);*/
    }

    /*background-color: fade(@darkgrey, 50%);*/
    .editing .sb__form-field-input {
        .opacity(1);
    }

    &.booking-custom {
        bottom: 20%;

        @media all and (max-width: 769px) {
            .tablet & {
                bottom: 50%;
            }
        }
    }

    

    #sb-container {

        .sb {
            @media all and (min-width: 769px) {
                background-color: transparent!important;
            }
        }
        .sb__form-field--guests,
        .sb__form-field-input {
            border-radius: 0;
            border: 1px solid #fff;
            line-height: 44px;

            .sb__form-field-icon {
                top: 9px;
                line-height: 0;

                .icon.sb-custom-icon-color {
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .sb__btn--verify {
            // margin-top: 0;
            border-radius: 0;
            text-transform: uppercase;
            border: 1px solid #fff;
            /*height: 40px;*/
            padding: 8px;
            position: relative;

            &:hover {
            }
        }

        .sb__form {
            /*@media all and (min-width: 769px) {
                width: 84%;
                float: left;
            }*/
        }
        .sb__footer-actions {

           /* @media all and (min-width: 769px) {
                float: left;
                width: 15%;
                margin-left: 1%;
                line-height: 1;
            }*/

            a.sb__footer-link {
                margin-top: 5px;

                &--edit {
                    float: left;
                }
            }
        }

        .sb__guests-add-room {
            border-radius: 0;
            border: 1px solid #fff;

            &:hover,
            &:focus {
                box-shadow: none;
            }
        }

        .sb__btn {
                border-radius: 0;
                text-transform: uppercase;
                border: none;
                color: @white!important;
        }

        .sb__calendar-btn {
            border-radius: 0;
        }

        @media all and (max-width: 768px) {
            .sb__form-field--checkout,
            .sb__guests-rooms,
            .sb__btn--verify {
                margin-top: 7px;

                .tablet & {
                    margin-top: 0;
                }
            }

            .sb__btn--verify {
                .tablet & {
                    width: 25%;
                }
            }

            .sb__footer-link {
                text-align: left;
                margin-top: 5px!important;
            }
        }

        .sb__form-field-label {
            display: none;
        }

        .sb__btn--verify {
            margin-top: 0;
        }
        .sb__form-field-date-number {
            line-height: 16px;
        }

        .sb__guests-adults:hover .sb__guests-adults-label, .sb__guests-children:hover .sb__guests-children-label,
        #sb-container .sb-custom-color-hover:hover {
            color: @gold!important;
            fill: @gold!important;
        }

        #sb-container_sb__form-field--guests {
            margin-bottom: 20px;
        }

        .sb-custom-label-color {
            color: @black!important;
        }

        .sb input {
            border-color: @darkgrey;
        }
        .sb__btn {
            color: @darkgrey;
        }

        
    }

