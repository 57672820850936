header {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 5;

	.moduletablecollapse.navbar-collapse {
		padding-right: 0px !important;
		padding-left: 0px !important;
	}

    .headerTop {
        height: 30px;
        width: 100%;
    }

    .navbar-default {
        width: 350px;
        height: 100vh;
        .transition-multiple(all ease-in-out .3s, color ease-in-out .1s .5s);
        left: 0;
        position: fixed;
        .border-radius(0);
        
        &:hover {
            background-color: fade(@darkgrey, 50%);
        }

        .navbar-brand {
            img.img-affix {display: block;}
            img.img-affix-top {display: none;}
        }

        &.affix-top {
            .navbar-brand {
                img.img-affix {display: none;}
                img.img-affix-top {display: block;}
            }
        }

        &.affix,
        .no-background & {
            left: -350px;

            .navbar-brand {
                img.img-affix {display: block;}
                img.img-affix-top {display: none;}
            }  

            .navbar-nav {
                text-align: center;

                > li {
                    &.open {
                        >a {
                            background-color: @darkgrey;
                            color: @white!important;

                            &:hover,
                            &:focus {
                                background-color: fade(@black, 100%)!important;
                                color: white!important;

                                &:before {
                                    content: none;
                                }
                            }
                        }
                    }
                    &.dropdown {
                        >a:after {
                            border-top: 5px solid @darkgrey;
                        }

                        .dropdown-menu {
                            background-color: @darkgrey;
                        }
                    }
                    &.active {
                        > a {
                            color: @black;
                            .transition(all ease-in-out .3s);

                            &:before {
                                background-color: fade(@black, 30%);
                            }

                            &:hover,
                            &:focus {
                                background-color: fade(@black, 50%);
                                color: @white;

                                &:before {
                                    content: none;
                                }
                            }
                        }
                    }
                    > a {
                        color: @black;
                        .transition(all ease-in-out .3s);

                        &:before {
                            background-color: fade(@black, 40%);
                        }
                    }
                }
            }

            &:hover {
                background-color: fade(@black, 0%);
            }

            .toggle-menu {
                &.is-active {
                    right: 0!important;
                }
            }
        }

        &.toggle-menu {
            left: 0;
        }

        .toggle-sidebar {
            position: absolute;
            right: -90px;
            top: 0px;
            background-color: white;
            .opacity(0);
            .transition(all ease-in-out .3s);
            /*.box-shadow(inset 10px 0px 20px -10px fade(@black, 50%));*/

            .no-background & {
                right: -90px;
                .opacity(1);
            } 
        }

        &.affix-top {
            .toggle-sidebar {
                .keyframes(toggleOut;{
                    0%   { right: -200px; .opacity(1);}
                    100% { right: 0; .opacity(0);}
                });

                .animation(toggleOut .1s forwards ease-in-out);

                .no-background & {
                    .animation(none)!important;
                }
            }
        }

        .navbar-nav {
            font-size: 1.15em;
            text-align: center;

            > li {
               /* @media all and (min-width: 1800px) {
                    font-size: 1.2em;
                }*/

                >a {
                    @media all and (min-width: 1800px) {
                        padding-top: 7px;
                        padding-bottom: 7px;
                    }
                }

                &.open {
                    >a {
                        background-color: fade(@black, 50%);
                        color: @white;
                    }
                }

                &.dropdown {
                    >a:after {
                        content: "";
                        width: 0; 
                        height: 0; 
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-top: 5px solid @white;
                        top: 50%;
                        position: absolute;
                        .transform(translateY(-50%));
                        margin-left: 5px;
                    }

                    .dropdown-menu {
                        background-color: fade(@black, 50%);
                        padding: 0;

                        >li {
                            border-bottom: 1px solid fade(@black, 20%);

                            &:last-child {
                                border-bottom: none;
                            }

                            >a {
                                color: @white;
                                padding: 5px 20px;

                                &:hover,
                                &:focus {
                                    background-color: fade(@black, 50%);
                                }
                            }
                        }
                    } 
                }
                &.active {
                    > a {
                        /*color: @white;*/
                        .transition(all ease-in-out .1s);
                        position: relative;

                        &:before {
                            content: "";
                            position: absolute;
                            background-color: fade(@white, 30%);
                            height: 2px;
                            width: 60%;
                            bottom: 0;
                            left: 20%;
                            .transition(all ease-in-out .3s);
                        }

                        &:hover,
                        &:focus {
                            &:before {
                                background-color: fade(@white, 90%);
                            }
                        }
                    }
                }
                > a {
                    color: @white;
                    .transition(all ease-in-out .1s);
                    padding-top: 6px;
                    padding-bottom: 6px;
                    position: relative;

                    &:before {
                        content: "";
                        position: absolute;
                        background-color: fade(@white, 60%);
                        height: 2px;
                        width: 0%;
                        bottom: 0;
                        left: 20%;
                        .transition(all ease-in-out .3s);
                    }

                    &:hover,
                    &:focus {
                        &:before {
                            width: 60%;
                        }
                    } 
                }
            }
        }  

        /* */
    }
}

/* menu top*/
#menutop {
	display: block;
    /* border: solid; */
    position: relative;
    /* width: fit-content; */
    float: right;
    margin-right: 30px;
    margin-top: 2px;
	li, li a {
		display: inline;
		color: white;
		padding: 5px 10px;
		&:hover,:focus
		{
			text-decoration: underline !important;
			background-color: transparent !important;			
		}
	}
	 @media all and (max-width: 767px) {
		display: none;
    }
}


/*menu lingua header top*/
.langmenu {
    /*
	position: absolute;
    right: 20px;
    top: 0;
	*/
	position: relative;
    right: 20px;
    top: 0;
    float: right;
	
    @media all and (max-width: 767px) {
		position: absolute;
        right: 0;
    }

    .lang-inline {
        li {
            a {
                color: @white;
                letter-spacing: .3em;
                font-size: .7em;
                text-decoration: none;
                text-transform: lowercase;
                .transition(all ease-in-out .3s);

                &:hover {
                    color: @gold;
                }
            }

            &.lang-active {
                a {
                    color: @gold;
                }
            }
        }
    }
}

/*H1*/
.col-sm-12.h1 {
    margin: 0;
    padding: 0;
    float: left;
}

.h1 h1 {
    font-size: 12px;
    z-index: 6;
    left: 0;
    text-align: left;
    top: 0;
    margin: 8px;
    padding: 0;

    @media all and (max-width: 768px){
        width: 50%;
        overflow: hidden;
        line-height: 12px;
        height: 12px;
    }
}

/*-------------background-----------------------------------------------------*/
.background {
    height: 100vh;
    overflow: hidden;
}
.swiper-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 0!important;

    &.didascalia .swiper-wrapper .swiper-slide .claim {
        right: 30px!important;
        bottom: 30px!important;
        left: auto!important;
        .transform(translate(0%, 0%))!important;
        color: @white;
        width: 40%;
        text-align: right;

        @media all and (max-width: 992px) {
            text-align: center;
            width: 96%;
            bottom: 100px!important;
            right: 2%!important;
            right: 2%!important;
        } 

        >p {
            margin-bottom: 0;
        }
    }

    .swiper-wrapper {
        height: 100%;
    }

    &-background {
        height: 100vh;

        &.qnt {
            .swiper-slide .claim {
                top: 50%;
                .opacity(1);
            }
        }

        /*@media all and (max-width: 1024px) {
            height: 65vh!important;
        }*/

        .swiper-slide {
            >span {
                .box-shadow(inset 40vmax 0px 25vmax -30vmax fade(@black, 25%), inset 0 -200px 300px -100px fade(@black, 25%));
            }

            .claim {
                text-align: center;
                position: absolute;
                /*top: 100vh;
                margin-top: -60px;*/
                bottom: 0px;
                right:80px;
                .transform(translate(-50%, -50%));
                .opacity(1);
                .transition(all ease-in-out .15s);
                -webkit-filter: drop-shadow(0px 0px 10px rgba(0,0,0,1));
                filter: drop-shadow(0px 0px 10px rgba(0,0,0,1));



                h2 {
                    font-size: 4.6vw;
                    color: @white;
                    /*font-family: @serif;*/
                    margin-bottom: 50px;
                }

                a {
                    color: @white;
                }

                img {
                    width: 250px;
                }

            }

            .text {
                position: absolute;
                top: 50%;
                .transform(translateY(-50%));
                left: 50%;
                width: 45%;
                font-size: 3em;
                .text-shadow-double(1px 1px 1px @black, 0 0 20px @black);
                color: @white;
                font-style: italic;
                line-height: 1.1em;
            }
        }

        .tablet & {
            height: 100vh!important;
        }

        &-custom {
            height: 80vh!important;
            background-color: #000;

            .tablet & {
                height: 70vh!important;
            }

            .mobile & {
                height: 65vh!important;
            }
        }

       

    }

}

.social-menu{
    img{
        background-size: contain;
    }
    .conde { 
        margin-top: 1vw;

        img {
            width: 120px;
        }

        .affix-top & {
            /*display: none;*/
        }
    }
    .link {
        margin-top: 1vw;

        &:before {
            .opacity(.75);
            font-size: 1.8em;
            color: @white;
            .transition(color .1s 0s);

            .affix & {
                color: @gold;
                .transition(color .1s 1s);
            }
        }

        &:hover,
        &:focus {
            .transform(scale(0.9));
            .transition(all ease-in-out .3s);

            &:before {
                .opacity(1);
            }
        }
  }
}


/*da mergiare*/
.swiper-container-background{
        .swiper-button {
        width: 120px;
        position: absolute;
        bottom: 30px;
        right:30px;

        .swiper-button-next,
        .swiper-button-prev {
            color: #fff;
            z-index: 5;
            font-family: "icomoon";
            position: relative;
            width: 50px;
            height: 50px;
            background-color: transparent;
            text-align: center;
            .box-shadow(inset 0 0 0 3px white);
            float: right;
        }

        .swiper-button-next {
            right: 0;
            margin-left: 20px;

            &:before {
                content: "\e907";
                line-height: 50px;
            }
        }
        .swiper-button-prev {
            left: 0;

            &:before {
                content: "\e906";
                line-height: 50px;
            }
        }
    }
}

.go-down {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    bottom: auto;
    top: 100vh;
    font-size: 1.5em;
    margin-top: -80px;
    left: 50%;
    .transform(translateX(-50%));
    height: 50px;
    width: 50px;
    .box-shadow(inset 0 0 0 3px @white);
    .transition(all ease-in-out .3s);

    &:before {
        position: absolute;
        left: 50%;
        top: 50%;
        .transform(translate(-50%, -50%));
        color: @white;
    }

    &:hover {
    }
}
.classCountryVille {
	li.hotel {
		display: none;
	}
	li.ville {
		width: 100% !important;
	}
	.box-prenotazione .box .tab-control .nav-stacked>li>a>span {
		display: inline;
	}
}

.classHotel  {
	li.hotel {
		width: 100% !important;
}
     li.ville {
		display: none;
	}
	.box-prenotazione .box .tab-control .nav-stacked>li>a>span {
		display: inline;
	}
}

li.castello {
    display: none;
}

.classCastello {
	li.castello {
		display: inline;
		width: 100% !important;
		span {
			display: inline !important;
		}
	}
     li.ville {
		display: none;
	}	
	li.hotel {
		display: none;
	}	
	.tab-pane.hotel, .tab-pane ville{
		display: none;		
	}
	
	.tab-pane.castello{
		display: block;
		opacity: 1;
		background-color: rgba(51,51,51,.85) !important;
	}
	
}

.banner-castello {
	z-index: 5;
    position: fixed;
    right: 0;
    top: 530px;
    width: 300px;
    transition: all ease-in-out .3s;
    -moz-transition: all ease-in-out .3s;
    -webkit-transition: all ease-in-out .3s;
	height: 90px;
    background-color: white;
	cursor: pointer;
}

.banner-castello h3 {
    background-color: #fff;
    cursor: pointer;
    margin: 0;
    height: 50px;
    line-height: 50px;
    width: 300px;
    text-align: center;
    color: #98844f;
    font-size: 2em;
    font-weight: bold;
}

.banner-castello h2 {
    font-size: 1.3em;
    text-align: center;
    margin: 0;
	cursor: pointer;
}

.banner-castello>h3:before {
    position: absolute;
    left: -50px;
    top: 0;
    width: 50px;
    height: 50px;
    background-color: #98844f;
    color: #fff;
    text-align: center;
    line-height: 50px;
}

.box-prenotazione {
    z-index: 6;
    position: fixed;
    right: -450px;
    top: 50px;
    width: 450px;
    .transition;

    &.aperto {
        right: 0;
        .transition(all ease-in-out .3s);
    }

    >h3 {
        cursor: pointer;
        position: absolute;
        margin: 0;
        height: 50px;
        line-height: 50px;
        
        width: 300px;
        text-align: center;

        &.chiuso {
            left: auto;
            right: 100%;
            background-color: fade(@darkgrey, 85%);
            color: @white;
            .transition(all ease-in-out .3s);
        }

        &.aperto {
            background-color: transparent;
            color: transparent;
            left: 0;
            right: auto;
            .transition(all ease-in-out .3s);

            
            &:before {
                .transform(rotate(180deg));
            }
        }

        &:before {
            position: absolute;
            left: -50px;
            top: 0;
            width: 50px;
            height: 50px;
            background-color: @gold;
            color: @white;
            text-align: center;
            line-height: 50px;
        }
    }

    .box {

        .tab-control {
            .nav-stacked {
                >li {
                    width: 50%;
                    text-align: center;
                    margin: 0;
                    .transition;

                    &.hotel {
                        background-color: fade(@darkgrey, 85%);
                    }

                    &.ville {
                        background-color: fade(#616161, 85%);
                    }
					&.castello {
                       background-color: fade(@darkgrey, 85%);
                    }

                    >a {
                        color: @white;

                        >span {
                            display: block;
                        }
                        
                        &:hover,
                        &:focus {
                            background-color: transparent;
                        }
                    }
                }
            }
        }

        .tab-content {
            .sb-screen-xs .sb__dates .sb__form-field {
                width: 100%;
                
                &:first-child {
                    margin-bottom: 20px;
                }
            }
            #sb-container .sb-custom-link-color .sb-custom-icon-color {
                color: @white!important;
                fill: @white!important;
            }
        }

        .tab-pane {
            padding: 20px;

            &.hotel {
                background-color: fade(@darkgrey, 85%);
            }
            &.ville {
                background-color: fade(#616161, 85%);
            }
			&.castello {
                background-color: fade(#616161, 85%);
            }
        }
    }


}

